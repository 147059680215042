import './Sponsorship.scss';
import { useEffect, useState } from 'react';
import SideBar from '../../components/SideBar/SideBar';
import TopNav from '../../components/TopNav/TopNav';
import api from "../../services/api";
import TableColumn from '../../components/TableColumn/TableColumn';
import { useNavigate } from 'react-router-dom';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaRegTimesCircle, FaCheck } from "react-icons/fa";
import exit from '../../assets/images/icons/exit.svg';
import { toast } from 'react-toastify';

function Sponsorship() {
    const navigate = useNavigate()
    const [events, setEvents] = useState([]);

    const dateFormatOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };

    const [guestlistDetails, setGuestlistDetails] = useState({
        show: false,
        guestlist: {}
    });


    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const records = 10;

    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState("null");


    async function getGuestlists(page) {
        try {
            const response = await api.get("/guest-list/search/requests", {
                params: {
                    page: page + 1,
                    records: records,
                    sort_column: sortBy,
                    sort: sortDirection,
                },
            });

            const itensTableColumns = response.data.data.map((item) => {
                const startDate = new Date(item.start_date);
                const formattedDate = startDate.toLocaleDateString('en-US', dateFormatOptions);

                return ({
                    "": (
                        <div>
                            <MdOutlineRemoveRedEye
                                onClick={() => setGuestlistDetails({
                                    show: true,
                                    guestlist: item
                                })}
                                size={30} color='rgba(167, 41, 245, 1)' style={{ cursor: "pointer" }} />
                        </div>
                    ),
                    // "": (
                    //     <div>
                    //         <MdOutlineRemoveRedEye
                    //             onClick={() => navigate(`/events/edit/${item.event_id}`)}
                    //             size={30} color='rgba(167, 41, 245, 1)' style={{ cursor: "pointer" }} />
                    //     </div>
                    // ),
                    'Start Time': formattedDate,
                    Event: (
                        <div style={{
                            color: 'rgba(167, 41, 245, 1)', textDecoration: 'underline', cursor: 'pointer'
                        }} onClick={() => navigate(`/events/edit/${item.event_id}`)}>
                            {item.event_name}
                        </div>
                    ),
                    Business: item.venue_name,
                    "VIP Only": item.is_vip_guest_list ? 'Yes' : 'No',
                    Status: item.review_status,
                    'Total Guests': item.guests_total,
                    'Guests Type': item.guests_type,
                    'Accept/Decline': (
                        <div style={{ cursor: 'pointer' }}>
                            {item.review_status === 'PENDING' ? (
                                <div style={{
                                    display: 'flex', alignItems: 'center', gap: 20
                                }}>
                                    <FaCheck
                                        title='Accept'
                                        size={20}
                                        color="rgba(5, 229, 108, 1)"
                                        onClick={() => handleAcceptSpotlight(item._id)}
                                    />

                                    <FaRegTimesCircle
                                        title='Decline'
                                        size={20}
                                        color='rgba(247, 6, 6, 1)'
                                        onClick={() => handleDeclineSpotlight(item._id)}
                                    />
                                </div>
                            ) : ("–")}
                        </div>
                    ), id: item._id
                })
            })


            setTotalPages(response.data.total);
            setEvents(itensTableColumns);
        } catch (error) {
            console.log(`events get request didn't work. error: ${error}`);
        }
    }

    const reloadEvents = async () => {
        getGuestlists(0)
    }

    useEffect(() => {
        getGuestlists(currentPage);
    }, [currentPage, sortBy, sortDirection]);

    const handleAcceptSpotlight = async (spotlightId) => {
        if (window.confirm("Are you sure you want to approve this request?")) {
            try {
              await api.post(`/guest-list/review-request/${spotlightId}`, {
                reviewStatus: 'approved',
                reasonForDisapproval: ''
              });
      
              toast.success("This request was accepted!");
        
              reloadEvents();
            } catch (error) {
              console.log('Error Processing Request ==> ', error);
              toast.error(
                "An error occurred, try again later. If persists, contact system administrator!"
              );
            }
            
        }
    }

    const handleDeclineSpotlight = async (spotlightId) => {
        let reason = prompt("Are you sure you want to deny this request? \n\n Please provide a reason below");
        if (!(reason == null)) {
            try {
                await api.post(`/guest-list/review-request/${spotlightId}`, {
                    reviewStatus: 'disapproved',
                    reasonForDisapproval: (reason || '')
                });

                toast.success("This request was rejected!");
                reloadEvents();
            } catch (error) {
                console.log('Error Processing Request ==> ', error);
                toast.error(
                    "An error occurred, try again later. If persists, contact system administrator!"
                );
            }
        }
    }

    const columns = [
        "",
        "Start Time",
        "Event",
        "Business",
        "VIP Only",
        "Status",
        "Total Guests",
        "Guests Type",
        "Accept/Decline",
    ];

    const sortableColumns = ["Event", "Time", 'Status'];

    const getStatusColor = (status) => {
        console.log(status)
        switch (status) {
            case 'Declined':
                return 'rgba(231, 76, 60, 0.1)';
            case 'Active':
                return 'rgba(46, 204, 113, 0.1)';
            case 'New':
                return 'rgba(241, 196, 15, 0.1)';
            case 'Expired':
                return 'rgba(231, 76, 60, 0.1)';
            default:
                return 'inherit';
        }
    };


    const sortColumnsMapping = {
        Event: "event_name",
        Status: "status",
    };

    const handleSort = (columnName) => {
        const backendColumnName = sortColumnsMapping[columnName];
        if (sortBy === backendColumnName) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortBy(backendColumnName);
            setSortDirection("asc");
        }
    };

    return (
        <main>
            <TopNav />
            <div className='main-container'>
                <SideBar />
                <article style={{ width: '100%', display: 'flex', justifyContent: 'center', overflow: "hidden" }}>
                {
                    (guestlistDetails.show) ? (
                        <section className='delete-modal'>
                        <article className='delete-modal__modal' style={{maxWidth: '600px', maxWidth: 'calc(100vw - 32px)'}}>
                            <div className='delete-modal__modal-top' style={{padding: '0.75rem 1rem'}}>
                                Guestlist Requested Details
                                <img style={{cursor: 'pointer'}} onClick={() => setGuestlistDetails({show: false, guestlist: {}})} src={exit} alt='white x'></img>
                            </div>
                            <div className='delete-modal__modal-content'>
                                <div style={{
                                    display: 'flex', flexDirection: 'column', gap: '20px', fontWeight: 300, 
                                }}>
                                    <div>
                                        <span style={{opacity: 0.8}}>Event:</span>{' '}
                                        <span >{guestlistDetails.guestlist?.event_name}</span>
                                    </div>
                                    <div>
                                        <span style={{opacity: 0.8}}>Business:</span>{' '}
                                        <span >{guestlistDetails.guestlist?.venue_name}</span>
                                    </div>
                                    <div>
                                        <span style={{opacity: 0.8}}>No. of guests:</span>{' '}
                                        <span >{guestlistDetails.guestlist?.guests_total}</span>
                                    </div>
                                    <div>
                                        <span style={{opacity: 0.8}}>Type of guests:</span>{' '}
                                        <span >{guestlistDetails.guestlist?.guests_type}</span>
                                    </div>
                                    <div>
                                        <span style={{opacity: 0.8}}>Business reviews guestlist:</span>{' '}
                                        <span >{guestlistDetails.guestlist?.review_guest ? 'Yes' : 'No'}</span>
                                    </div>
                                    <div>
                                        <span style={{opacity: 0.8}}>VIP only:</span>{' '}
                                        <span >{guestlistDetails.guestlist?.is_vip_guest_list ? 'Yes' : 'No'}</span>
                                    </div>
                                    <div>
                                        <span style={{opacity: 0.8}}>Start date:</span>{' '}
                                        <span >{
                                            guestlistDetails.guestlist?.created_at 
                                            ? (new Date(guestlistDetails.guestlist?.start_date)).toLocaleDateString('en-US', dateFormatOptions)
                                            : '-'
                                        }</span>
                                    </div>
                                    <div>
                                        <span style={{opacity: 0.8}}>End date:</span>{' '}
                                        <span >{
                                            guestlistDetails.guestlist?.created_at 
                                            ? (new Date(guestlistDetails.guestlist?.end_date)).toLocaleDateString('en-US', dateFormatOptions)
                                            : '-'
                                        }</span>
                                    </div>
                                    <div>
                                        <span style={{opacity: 0.8}}>Allow waitlist:</span>{' '}
                                        <span >{guestlistDetails.guestlist?.allow_wait_list ? 'Yes' : 'No'}</span>
                                    </div>
                                    <div>
                                        <span style={{opacity: 0.8}}>Free drink vouchers:</span>{' '}
                                        <span >{guestlistDetails.guestlist?.vouchers_total_quantity || 0}</span>
                                    </div>
                                    <div>
                                        <span style={{opacity: 0.8}}>Date Request Sent:</span>{' '}
                                        <span >{
                                            guestlistDetails.guestlist?.created_at 
                                            ? (new Date(guestlistDetails.guestlist?.created_at)).toLocaleDateString('en-US', dateFormatOptions)
                                            : '-'
                                        }</span>
                                    </div>
                                    <div>
                                        <span style={{opacity: 0.8}}>Description:</span>{' '}
                                        <span >{guestlistDetails.guestlist?.description}</span>
                                    </div>
                                </div>
                            </div>
                        </article>
                        </section>
                    ) : (<></>)
                    }
                    <section className='sponsorship'>
                        <TableColumn
                            title="Guestlist requests"
                            columns={columns}
                            data={events}
                            sortableColumns={sortableColumns}
                            hasExport={false}
                            hasSearch={false}
                            getStatusColor={getStatusColor}

                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={setCurrentPage}
                            records={records}

                            onSort={handleSort}
                        />
                    </section>
                </article>
            </div>
        </main>
    )
}

export default Sponsorship;
