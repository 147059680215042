import "./SpotlightEvent.scss";
import api from "../../services/api";
import TopNav from "../../components/TopNav/TopNav";
import SideBar from "../../components/SideBar/SideBar";
import SelectForm from "../../components/SelectForm/SelectForm";
import InputForm from "../../components/InputForm/InputForm";
import { Link, useNavigate, useParams } from "react-router-dom";
import chevronLeft from "../../assets/images/icons/chevron-left.svg";
import bin from "../../assets/images/icons/delete.svg";
import checkMark from "../../assets/images/icons/check-mark.svg";
import { MdAccountBalanceWallet } from "react-icons/md";
import { useState, useEffect } from "react";
import RadioButton from "../../components/RadioButton/RadioButton";
import * as Yup from "yup";

const SpotlightEvent = () => {
  const navigate = useNavigate();

  const { eventId } = useParams();
  const [numberOfGuests, setNumberOfGuests] = useState(undefined);
  const [typeOfGuest, setTypeOfGuest] = useState(undefined);
  const [price, setPrice] = useState(undefined);
  const [freeDrinkVouchers, setFreeDrinkVouchers] = useState(undefined);

  const [errors, setErrors] = useState({});

  const validationSchema = Yup.object().shape({
    // numberOfGuests: Yup.number()
    //   .typeError("Number of guests must be a number")
    //   .required("Number of guests is required"),
    // typeOfGuest: Yup.string().required("Type of guest is required"),
    // price: Yup.number()
    //   .typeError("Event price must be a number")
    //   .required("Event price is required"),
    // freeDrinkVouchers: Yup.number()
    //   .typeError("Free Drink Voucher quantity must be a number")
    //   .required("Free Drink Voucher quantity is required"),
    selectedPeriod: Yup.number()
      .typeError("Period must be a number")
      .required("Period is required"),
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      await validationSchema.validate(
        {
          // numberOfGuests,
          // typeOfGuest,
          // price,
          // freeDrinkVouchers,
          selectedPeriod,
        },
        { abortEarly: false }
      );

      const newSpotlight = {
        event_id: eventId,
        guests: numberOfGuests,
        guest_type: typeOfGuest,
        price: price,
        days: selectedPeriod,
        free_drink_vouchers: freeDrinkVouchers,
      };

      console.log(newSpotlight)

      const response = await api.post(`/spotlights`, newSpotlight);

      window.open(response.data.payment_link, "_blank");

      setErrors({});
      navigate("/spotlight");
    } catch (err) {
      console.log(`Error updating user. This was the error: ${err}`);
      setErrors(
        err.inner.reduce((errorObj, currentError) => {
          return {
            ...errorObj,
            [currentError.path]: currentError.message,
          };
        }, {})
      );
    }
  };

  const optionsTypeOfGuest = [
    { label: "Everyone", value: "Everyone" },
    { label: "Guys only", value: "Male" },
    { label: "Girls only", value: "Female" },
  ];

  const options = [
    { label: "1 day", value: "1" },
    { label: "1 week", value: "7" },
    { label: "1 month", value: "30" },
  ];

  const [selectedPeriod, setSelectedPeriod] = useState();

  const handlePeriodChange = (selectedOption) => {
    setSelectedPeriod(selectedOption);
  };

  return (
    <main>
      <TopNav />
      <div className="main-container">
        <SideBar />

        <article
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <section className="edit-event">
            <div className="edit-event__top" style={{ marginBottom: "2vw" }}>
              <div className="edit-event__top-left">
                <Link to="/spotlight">
                  <img
                    className="edit-event__top-left-icon"
                    src={chevronLeft}
                    alt="Left pointing chevron"
                  ></img>
                </Link>
                <h1 className="edit-event__top-left-heading">
                  Spotlight event
                </h1>
              </div>
            </div>

            <form onSubmit={handleFormSubmit}>
              <section className="spotlight-event">
                <div>
                  <div>
                    <InputForm
                      placeholder="Number of guests"
                      label="Number of guests"
                      type="number"
                      id="guests"
                      name="guests"
                      value={numberOfGuests}
                      onChange={(event) =>
                        setNumberOfGuests(event.target.value)
                      }
                      error={errors.numberOfGuests}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <SelectForm
                      label="Type of guest"
                      options={optionsTypeOfGuest}
                      value={typeOfGuest?.value}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        setTypeOfGuest(selectedOption.value);
                      }}
                      error={errors.typeOfGuest}
                    />
                  </div>

                  <div>
                    <InputForm
                      placeholder="Event price"
                      label="Event price"
                      type="text"
                      id="price"
                      name="price"
                      value={price}
                      onChange={(event) => setPrice(event.target.value)}
                      error={errors.price}
                    />
                  </div>
                </div>

                <div className="container-radio">
                  <RadioButton
                    title="Period"
                    options={options}
                    onChange={handlePeriodChange}
                    error={errors.selectedPeriod}
                  />
                </div>

                <div>
                  <div>
                    <InputForm
                      placeholder="Enter Quantity"
                      label="Free Drink Voucher quantity"
                      type="text"
                      id="freeDrinksVouchers"
                      name="freeDrinksVouchers"
                      value={freeDrinkVouchers}
                      error={errors.freeDrinkVouchers}
                      onChange={(event) =>
                        setFreeDrinkVouchers(event.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="container-button">
                  <button type="submit">
                    Total: $100 - Pay now
                    <MdAccountBalanceWallet size={30} color="#fff" />
                  </button>
                </div>
              </section>
            </form>
          </section>
        </article>
      </div>
    </main>
  );
};

export default SpotlightEvent;
