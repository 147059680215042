import { useCallback, useEffect, useMemo, useState } from "react";

import "./index.scss";

const Dropdown = ({ data = [], defaultValue, onChange, except = [] }) => {
  const [opened, setOpened] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  useEffect(() => {
    setSelectedOption(defaultValue);
  }, [defaultValue]);

  const handleDropdownClick = useCallback(() => {
    setOpened((oldState) => !oldState);
  }, []);

  const handleDropdownItemClick = useCallback(
    (value, index) => {
      setSelectedOption(value);
      setOpened(false);

      if (onChange) onChange({ value, index });
    },
    [onChange]
  );

  return (
    <div id="dropdown">
      <button
        onClick={handleDropdownClick}
        type="button"
        className="dropdown-controller"
      >
        <span>
          {data.find((item) => item.value === selectedOption)?.label ||
            "Select a day"}
        </span>
      </button>

      {opened && (
        <div className="dropdown-content">
          {data.map(({ label, value }, index) => {
            const shouldBeOmitted = except.some((item) => item === value);

            if (shouldBeOmitted) return null;

            return (
              <button
                key={value}
                type="button"
                onClick={() => handleDropdownItemClick(value, index)}
              >
                {label}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const HoursOfOperation = ({
  onChangeTime,
  selectDay = true,
  onChangeDay,
  excludeDays = [],
  defaultValues = {
    day: "",
    hours: {
      from: "",
      to: "",
    },
  },
}) => {
  const daysOfWeek = useMemo(() => {
    return [
      { value: "sunday", label: "Sunday" },
      { value: "monday", label: "Monday" },
      { value: "tuesday", label: "Tuesday" },
      { value: "wednesday", label: "Wednesday" },
      { value: "thursday", label: "Thursday" },
      { value: "friday", label: "Friday" },
      { value: "saturday", label: "Saturday" },
    ];
  }, []);

  const handleOnChange = useCallback(
    (type, event) => {
      if (onChangeTime) {
        const { value } = event.target;
        let hoursPart = value;
        let periodPart;

        if (value.indexOf(" ") !== -1) {
          const [first, second] = value.split(" ");

          hoursPart = first;
          periodPart = second;
        }

        const [hours, minutes] = hoursPart
          .split(":")
          .map((item) => Number(item));
        let parsedHours = hours;

        if (periodPart === "PM" && hours < 12) {
          parsedHours += 12;
        } else if (periodPart === "AM" && hours === 12) {
          parsedHours = 0;
        }

        const hoursToMinutes = parsedHours * 60;

        onChangeTime({
          type,
          value: hoursToMinutes + minutes,
        });
      }
    },
    [onChangeTime]
  );

  console.log(defaultValues);

  return (
    <div id="container">
      {selectDay && (
        <Dropdown
          data={daysOfWeek}
          defaultValue={defaultValues.day}
          onChange={onChangeDay}
          except={excludeDays}
        />
      )}

      <span>from</span>

      <input
        type="time"
        onChange={(event) => handleOnChange("from", event)}
        defaultValue={defaultValues.hours.from}
      />

      <span>to</span>

      <input
        type="time"
        onChange={(event) => handleOnChange("to", event)}
        defaultValue={defaultValues.hours.to}
      />
    </div>
  );
};
