import { styled } from "styled-components";

export const ContainerForm = styled.form`
  > div:nth-child(1) {
    display: flex;
    gap: 16px;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 16px;

      width: 100%;
    }
  }
`;

export const ContainerDate = styled.div`
  display: flex;

  gap: 16px;

  margin-top: 32px;

  margin-bottom: 65px;

  @media (max-width: 767px) {
      flex-wrap: wrap;
      gap: 24px;
      margin-bottom: 36px;
  }

  > div {
    flex: 1;

    display: flex;
    flex-direction: column;

    > h1 {
      font-size: 18px;
      color: rgba(255, 255, 255, 1);
      font-weight: 600;
      margin-bottom: 16px;
    }

    > div {
      display: flex;
      align-items: flex-start;
      gap: 16px;

      > div {
        flex: 1;
      }
    }
  }
`;

export const ContainerError = styled.div`
  display: flex;
  align-items: center;

  > h1 {
    font-size: 18px;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;

    padding: 0px 16px;
  }
`;

export const ErrorMessage = styled.p`
  color: #ff0000;
  font-size: 0.8em;
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: 47px;

  padding: 20px 0;

  > a {
    align-items: center;
    display: flex;
    text-decoration: none;

    gap: 2.5vw;
  }

  > h1 {
    color: #fff;
    font-size: 2rem;
    font-weight: 400;
  }

  > a {
    width: max-content;
  }

  > div {
    width: 50%;
  }
`;

export const ContainerTickets = styled.div`
  display: flex;

  flex-direction: column;

  gap: 16px;

  @media (max-width: 767px) {
      flex-wrap: wrap;

      > div:nth-child(1) {
        width: 100%;
        flex-wrap: wrap;

      }
  }

  > div:nth-child(1) {
    display: flex;
    gap: 24px;
    padding-bottom: 20px;

    > div {
      width: 100%;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: start;
  flex-direction: row !important;

  gap: 12px;

  height: auto;

  margin-top: 20px;

  > a {
    text-decoration: none;
    padding: 16px 45px 16px 45px;

    border: 1.5px solid rgba(255, 255, 255, 1);

    border-radius: 8px;

    color: rgba(255, 255, 255, 1);

    font-size: 18px;
  }
`;

export const ContainerRadioButton = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 16px;

  margin: 32px 0;

  .container-radio {
    > div {
      display: flex;
      flex-direction: column;

      .content-radio {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        > label {
          margin: 0;
        }
      }
    }
  }
`;

export const ContainerAddVenue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    border: 1.5px solid rgba(255, 255, 255, 1);
    border-radius: 8px;

    padding: 16px 2px;

    background: transparent;

    color: #fff;

    width: 100%;

    font-size: 18px;

    opacity: 1;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;

export const VenueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
`;

export const VoucherInput = styled.input`
  width: 100px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

export const RemoveButton = styled.button`
  background: #e74c3c;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: #c0392b;
  }
`;

export const ContainerAddAnotherVenue = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;

  > div {
    display: flex;
    align-items: center;

    margin-bottom: 32px;

    width: 100%;

    gap: 16px;

    > div {
      width: 100%;
    }
  }
`;

export const ContainerImg = styled.div`
  display: flex;
  gap: 16px;

  margin: 24px 0;

  div:nth-child(2) {
    display: none !important;
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 32px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 24px;

    > div {
      width: 100%;
      flex: auto!important;
    }
  }

  > div {
    flex: 1;
  }

  > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: transparent;
      border: none;
      outline: none;

      color: #fff;
      font-size: 14px;
    }
  }
`;
