import {
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signInWithPopup,
    sendPasswordResetEmail,
    signOut
} from "firebase/auth";
import { auth } from "./firebaseConfig"; // Import the Firebase authentication object from FirebaseConfig
//   import { updateUserInFirestore } from "./FirebaseStore";
import api from '../services/api'
import { addMinutes } from "date-fns";


export const handleSignUp = async (email, password, fullName) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
        );
        const { user } = userCredential;

    } catch (error) {
        console.error("Sign-up error:", error);
    }
};

export const handleSignIn = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password
        );
        const { user } = userCredential;

        if (user) {
            const { data } = await api.get(`/users/${user.uid}`);
            const { user: apiUser } = data;

            // localStorage.setItem('@ZonaAdmin:Auth', JSON.stringify(apiUser));

            if (!apiUser.admin) {
                await auth.signOut();
                alert('You do not have the rights to access this service!');

                return;
            }

            // if (apiUser.two_factor_auth === true) {
            //     await api.post(`/users/send-phone-verification`, {
            //         phone: apiUser.phone,
            //     });

            //     const verification = {
            //         type: '2FA',
            //         id: apiUser.phone,
            //         verified: false,
            //         expires: addMinutes(Date.now(), 15).getTime(),
            //     };

            //     localStorage.setItem('2FA@ZonaAdmin', JSON.stringify(verification));
            // } else {
            //     const verification = {
            //         type: '2FA',
            //         id: apiUser.phone,
            //         verified: true,
            //         expires: addMinutes(Date.now(), 15).getTime(),
            //     };

            //     localStorage.setItem('2FA@ZonaAdmin', JSON.stringify(verification));
            // }

            const idToken = await user.getIdToken();

            api.defaults.headers.authorization = `Bearer ${idToken}`;
        }

    } catch (error) {
        throw error;
    }
};

export const resetPassword = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
    } catch (error) {
        console.log(error);
    }
};
