import "../../../styles/CalendarStyles.css";
import "./PromotersNew.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4, v4 } from "uuid";
import * as Yup from "yup";

import checkMark from "../../../assets/images/icons/check-mark.svg";
import chevronLeft from "../../../assets/images/icons/chevron-left.svg";
import Image from "../../../components/Image";
import InputForm from "../../../components/InputForm/InputForm";
import SelectForm from "../../../components/SelectForm/SelectForm";
import SideBar from "../../../components/SideBar/SideBar";
import TopNav from "../../../components/TopNav/TopNav";
import api from "../../../services/api";
import { format, parseISO, isValid } from "date-fns";
import Button from "../../../components/Buttons/Button";

const PromotersNew = () => {
  const navigate = useNavigate();

  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [ig, setIg] = useState();

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [promoterImagens, setPromoterImagens] = useState([{ id: v4() }]);
  const [venues, setVenues] = useState([]);

  const [listBusiness, setListBusiness] = useState([]);

  const options = [
    { label: "Monday", value: "monday" },
    { label: "Tuesday", value: "tuesday" },
    { label: "Wednesday", value: "wednesday" },
    { label: "Thursday", value: "thursday" },
    { label: "Friday", value: "friday" },
    { label: "Saturday", value: "saturday" },
    { label: "Sunday", value: "sunday" },
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Promoter Name is required"),
    // phone: Yup.string().required("Phone number is required"),
    // email: Yup.string()
    //   .email("Invalid email")
    //   .required("Email is required")
    //   .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),
    ig: Yup.string().required("Instagram is required"),
  });

  const handleAddUserSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);

      await validationSchema.validate(
        {
          name,
          phone,
          email,
          ig,
        },
        { abortEarly: false }
      );

      const formData = new FormData();

      promoterImagens.forEach((img) => {
        if (img?.file && img.file instanceof Blob) {
          formData.append(`photo`, img.file);
        }
      });

      const formattedVenues = venues?.map((venue) => ({
        venue_id: venue.selectedVenues.value,
        days: Object.keys(venue).filter(
          (key) => options.some((option) => option.value === key) && venue[key]
        ),
        expires:
          venue.startDate instanceof Date ? venue.startDate.getTime() : null,
      }));

      const newUser = {
        name,
        phone,
        photo: promoterImagens.length > 0 && promoterImagens[1]?.file,
        email,
        ig,
        venues: formattedVenues,
      };

      await api.post("/promoters", newUser, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setName();
      setPhone();
      setEmail();
      setIg();
      setVenues([]);

      toast.success("Promoters Added successfully!");

      navigate("/promoters/list");

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
        toast.error(
          `Error Added Promoters. Please try again, ${error.message}`
        );
      } else {
        console.error("Error adding user:", error);
      }
      setLoading(false);
    }
  };

  const optionsVenus = listBusiness.map((business) => ({
    value: business._id,
    label: business.name,
  }));

  useEffect(() => {
    const getBusiness = async () => {
      try {
        const response = await api.get(`/venues`, {
          params: {
            records: 99999,
            page: 1,
          },
        });
        setListBusiness(response.data.data);
      } catch (error) {
        console.log(`venues get request didn't work. error: ${error}`);
      }
    };
    getBusiness();
  }, []);

  const handleAddVenue = () => {
    setVenues([
      ...venues,
      { id: uuidv4(), selectedVenues: "", startDate: null },
    ]);
  };

  const renderVenueFields = (venue, index) => {
    const handleCategoryVenues = (selectedOption) => {
      const updatedVenues = [...venues];
      updatedVenues[index].selectedVenues = selectedOption;
      setVenues(updatedVenues);
    };

    const handleChange = (event) => {
      const { name, checked } = event.target;
      const updatedVenues = [...venues];

      updatedVenues[index][name] = checked;
      setVenues(updatedVenues);
    };

    const handleRemoveVenue = () => {
      const updatedVenues = [...venues];
      updatedVenues.splice(index, 1);
      setVenues(updatedVenues);
    };

    const handleDateChange = (date, index) => {
      const parsedDate = parseISO(date);

      if (!isValid(parsedDate)) {
        console.error("Invalid date:", date);
        return;
      }

      const updatedVenues = [...venues];
      updatedVenues[index].startDate = parsedDate;
      setVenues(updatedVenues);
    };

    return (
      <div key={venue.id} className="container-new-fields">
        <div className="container-new-venue">
          <h1>New Venue {index + 1}</h1>
          <span>
            <Button
                className='btn-outline-light'
                onClick={handleRemoveVenue}
                label='Remove Venue'
            />
          </span>
        </div>
        <div>
          <SelectForm
            label="Venue"
            options={optionsVenus}
            value={venue.selectedVenues}
            onChange={(selectedOption) => handleCategoryVenues(selectedOption)}
          />

          <div>
            <label>Expires</label>
            <input
              className="input-date"
              type="date"
              value={
                venue.startDate
                  ? format(new Date(venue.startDate), "yyyy-MM-dd")
                  : ""
              }
              onChange={(e) => handleDateChange(e.target.value, index)}
              placeholder="Select Date Expires"
            />
          </div>
        </div>
        <div className="container-radio-form">
          <label>Days working at venue</label>
          <div className="content-checkbox">
            {options.map((option) => (
              <div key={option.value} className="checkbox-container">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name={option.value}
                    checked={venue[option.value] || false}
                    onChange={handleChange}
                    className="checkbox-input"
                  />
                  <span className="checkmark"></span>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderDynamicVenueFields = () => {
    return venues.map(renderVenueFields);
  };

  const handleImageAdd = useCallback((event) => {
    const file = event.target.files[0];

    const image = {
      id: v4(),
      file,
    };

    setPromoterImagens((oldState) => [...oldState, image]);
  }, []);

  const handleImageRemove = useCallback((id) => {
    setPromoterImagens((oldState) => {
      const updatedState = oldState.filter((img) => img.id !== id);

      return updatedState;
    });
  }, []);

  const preprocessEmail = (email) => email.replace(/\s+/g, "");

  return (
    <main>
      <TopNav />
      <div className="main-container">
        <SideBar />
        <article
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <section className="add-user">
            <div className="add-user__top">
              <Link to="/promoters/list">
                <img
                  className="add-user__top-icon"
                  src={chevronLeft}
                  alt="chevron pointing left"
                ></img>
              </Link>
              <h1 className="add-user__top-heading">Adding new Promoter</h1>
            </div>

            <form className="add-user__form">
              <section className="add-user__form--left">
                <div>
                  <div>
                    <InputForm
                      placeholder="Enter Promoter Name"
                      label="Promoter Name"
                      type="text"
                      id="name"
                      name="name"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      error={errors.name}
                    />
                  </div>

                  <div>
                    <InputForm
                      placeholder="Enter Instagram Handle"
                      label="Instagram Handle"
                      type="text"
                      id="ig"
                      name="ig"
                      value={ig}
                      onChange={(event) => setIg(event.target.value)}
                      error={errors.ig}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <InputForm
                      placeholder="Enter Email"
                      label="Email"
                      type="text"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(event) =>
                        setEmail(preprocessEmail(event.target.value))
                      }
                      error={errors.email}
                    />
                  </div>
                  <div>
                    <InputForm
                      placeholder="Enter Phone Number"
                      label="Phone number"
                      type="text"
                      id="phone"
                      name="phone"
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                      error={errors.phone}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ display: "flex", gap: "20px" }}>
                    {promoterImagens.map((img) => {
                      return (
                        <Image
                          key={img.id}
                          onAdd={handleImageAdd}
                          onRemove={() => handleImageRemove(img.id)}
                        />
                      );
                    })}
                  </div>
                </div>

                <div style={{ flexDirection: "column" }}>
                  {renderDynamicVenueFields()}
                </div>
              </section>
            </form>

            <div className='d-flex align-item-center flex-wrap gap-3 pb-4 pt-2'>
                <Button 
                    primaryBtn
                    onClick={handleAddUserSubmit}
                    disabled={loading}
                    label={'Update information'}
                    iconifyIconName="material-symbols:check-rounded"
                />
                <Button
                    className='btn-outline-light'
                    disabled={loading}
                    onClick={handleAddVenue}
                    label='Add Venue + '
                />
            </div>
          </section>
        </article>
      </div>
    </main>
  );
};

export default PromotersNew;
