import './index.scss';

import logo from '../../assets/images/icons/logo.svg';
import { useCallback, useEffect, useMemo, useState } from 'react';
import api from '../../services/api';
import { auth } from '../../firebase/firebaseConfig';
import countryList from '../../json/countryCode.json';
import ReactCodeField from 'react-code-input';
import { useNavigate } from 'react-router-dom';

export default function TwoFactorAuth() {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [validation, setValidation] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [secondsToSendCodeAgain, setSecondsToSendCodeAgain] = useState(0);
  const [intervalId, setIntervalId] = useState();

  useEffect(() => {
    if (intervalId && secondsToSendCodeAgain === 0) {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
  }, [intervalId, secondsToSendCodeAgain]);

  useEffect(() => {
    async function loadUser() {
      const userCredentials = auth.currentUser;

      const { data } = await api.get(`/users/${userCredentials.uid}`);

      setUser(data.user);
    }

    loadUser();
  }, []);

  const implicitPhoneNumber = useMemo(() => {
    if (!user?.phone) return '';

    const phoneCountryCode = countryList.find(country => user.phone.includes(country.dial_code));
    const phoneWithoutDialCode = user.phone.replace(phoneCountryCode.dial_code, '');

    const init = phoneWithoutDialCode.length - 4;
    const last4Digits = phoneWithoutDialCode.substring(init);

    const implicitPhoneNumber = last4Digits.padStart(phoneWithoutDialCode.length, '*');

    return phoneCountryCode.dial_code + implicitPhoneNumber;
  }, [user]);

  const handleCodeVerification = useCallback(async event => {
    event.preventDefault();

    try {
      setLoading(true);

      const { data } = await api.post(`/users/verify-codes`, {
        id: user.phone,
        code,
      });

      if (!data.verified) {
        setValidation('Could not validate your code...');
        return;
      }

      const oldTwoFactorAuth = localStorage.getItem('2FA@ZonaAdmin');
      const newTwoFactor = JSON.parse(oldTwoFactorAuth);

      newTwoFactor.verified = true;

      localStorage.setItem('2FA@ZonaAdmin', JSON.stringify(newTwoFactor));

      navigate('/moderators');
    } catch (err) {
      if (err?.response?.data) setValidation(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }, [code, user, navigate]);

  const onCodeChange = useCallback(text => {
    setCode(text);
  }, []);

  const handleResendCode = useCallback(async () => {
    setSecondsToSendCodeAgain(60);

    await api.post('/users/send-phone-verification', { phone: user.phone });

    const interval = setInterval(() => {
      setSecondsToSendCodeAgain(oldState => oldState - 1);
    }, 1000);

    setIntervalId(interval);
  }, [user]);

  return (
    <div id="two-f-a">
      <form onSubmit={handleCodeVerification}>
        <img src={logo} alt="Logo" />

        <p>
          We sent a verification code to the phone number attached to your account <strong>{implicitPhoneNumber}</strong>
        </p>

        <ReactCodeField
          inputMode="numeric"
          fields={4}
          inputStyle={{
            backgroundColor: '#FFFFFF1A',
            borderRadius: 8,
            width: 60,
            height: 54,
            border: 0,
            fontSize: 32,
            color: '#fff',
            textAlign: 'center',
          }}
          onChange={onCodeChange}
        />

        {!!validation && <span>{validation}</span>}

        <div>
          {secondsToSendCodeAgain ? (
            <span>Code sent! Send again? ({secondsToSendCodeAgain})</span>
          ) : (
            <>
              <span>Didn't get a code?</span>

              <button disabled={!user?.phone} onClick={handleResendCode} type="button">
                Resend code
              </button>
            </>
          )}

        </div>

        <button disabled={code.length !== 4 || loading} type="submit">
          {loading ? 'Verifying...' : 'Confirm'}
        </button>
      </form>
    </div>
  );
}