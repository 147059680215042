import "../../../styles/CalendarStyles.css";
import "./PromotersEdit.scss";
import "react-datepicker/dist/react-datepicker.css";

import { format, parseISO, isValid } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Link, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import checkMark from "../../../assets/images/icons/check-mark.svg";
import chevronLeft from "../../../assets/images/icons/chevron-left.svg";
import Image from "../../../components/Image";
import InputForm from "../../../components/InputForm/InputForm";
import SelectForm from "../../../components/SelectForm/SelectForm";
import SideBar from "../../../components/SideBar/SideBar";
import TopNav from "../../../components/TopNav/TopNav";
import api from "../../../services/api";
import bin from "../../../assets/images/icons/delete.svg";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../../components/Buttons/Button";

const PromotersEdit = () => {
  const { promoterId } = useParams();
  const navigate = useNavigate();

  const [promotersListEdit, setPromotersListEdit] = useState();

  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [ig, setIg] = useState();

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [venues, setVenues] = useState([]);

  const [listBusiness, setListBusiness] = useState([]);

  const [images, setImages] = useState(null);

  const options = [
    { label: "Monday", value: "monday" },
    { label: "Tuesday", value: "tuesday" },
    { label: "Wednesday", value: "wednesday" },
    { label: "Thursday", value: "thursday" },
    { label: "Friday", value: "friday" },
    { label: "Saturday", value: "saturday" },
    { label: "Sunday", value: "sunday" },
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Promoter Name is required"),
    // phone: Yup.string().required("Phone number is required"),
    // email: Yup.string()
    //   .email("Invalid email")
    //   .required("Email is required")
    //   .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),
    ig: Yup.string().required("Instagram is required"),
  });

  const handleAddUserSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);

      await validationSchema.validate(
        {
          name,
          phone,
          email,
          ig,
        },
        { abortEarly: false }
      );

      const formattedVenues = venues?.map((venue) => ({
        venue_id: venue.selectedVenues ? venue.selectedVenues : venue.venue_id,
        days: options
          .filter((option) => venue.days.includes(option.value))
          .map((option) => option.value),
        expires:
          venue.expires instanceof Date
            ? venue.expires.getTime()
            : venue.expires,
      }));

      const newUser = {
        name,
        phone,
        // photo: promotersListEdit.photo,
        email,
        ig,
        venues: formattedVenues,
      };

      console.log(newUser);

      await api.put(`/promoters/${promoterId}`, newUser);

      setName();
      setPhone();
      setEmail();
      setIg();
      setVenues([]);

      toast.success("Promoters Edit successfully!");

      navigate("/promoters/list");

      setLoading(false);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
        toast.error(`Error Edit Promoters. Please try again, ${error.message}`);
      } else {
        console.error("Error adding user:", error);
      }
      setLoading(false);
    }
  };

  const optionsVenus = listBusiness?.map((business) => ({
    value: business._id,
    label: business.name,
  }));

  useEffect(() => {
    const getBusiness = async () => {
      try {
        const response = await api.get(`/venues`, {
          params: {
            records: 99999,
            page: 1,
          },
        });
        setListBusiness(response.data.data);
      } catch (error) {
        console.log(`venues get request didn't work. error: ${error}`);
      }
    };
    getBusiness();
  }, []);

  const handleAddVenue = () => {
    setVenues([
      ...venues,
      {
        id: uuidv4(),
        selectedVenues: null,
      },
    ]);
  };

  const renderVenueFields = (venue, index) => {
    const handleCategoryVenues = (selectedOption) => {
      const updatedVenues = [...venues];
      updatedVenues[index].selectedVenues = selectedOption;

      setVenues(updatedVenues);
    };

    const handleDayChange = (day, isChecked) => {
      setVenues((prevVenues) => {
        const updatedVenues = [...prevVenues];
        const venue = updatedVenues[index];

        if (!venue) return prevVenues;

        const days = venue.days || [];

        if (isChecked && !days.includes(day)) {
          updatedVenues[index] = { ...venue, days: [...days, day] };
        } else if (!isChecked && days.includes(day)) {
          updatedVenues[index] = {
            ...venue,
            days: days.filter((selectedDay) => selectedDay !== day),
          };
        }

        return updatedVenues;
      });
    };

    const handleRemoveVenue = () => {
      const updatedVenues = [...venues];
      updatedVenues.splice(index, 1);
      setVenues(updatedVenues);
    };

    const handleDateChange = (date, index) => {
      const parsedDate = parseISO(date);

      if (!isValid(parsedDate)) {
        console.error("Invalid date:", date);
        return;
      }

      const updatedVenues = [...venues];
      updatedVenues[index].expires = parsedDate;
      setVenues(updatedVenues);
    };

    const findVenueNameById = (venueId) => {
      // console.log("optionsVenus:", optionsVenus);
      const selectedVenue = optionsVenus.find((venue) => {
        // console.log("Comparando:", venue.value, venueId);
        return venue.value === venueId;
      });

      return selectedVenue ? selectedVenue.label : "Unknown Venue";
    };

    return (
      <div key={venue.id} className="container-new-fields">
        <div className="container-new-venue">
          <h1>New Venue {index + 1}</h1>
          <button type="button" onClick={handleRemoveVenue}>
            Remove Venue
          </button>
        </div>
        <div>
          <SelectForm
            placeholder={findVenueNameById(venue?.venue_id)}
            label="Venue"
            options={optionsVenus}
            value={venue?.venue_id}
            onChange={(selectedOption) => {
              console.log(selectedOption);
              handleCategoryVenues(selectedOption.value);
            }}
          />

          <div>
            <label>Expires</label>
            <input
              className="input-date"
              type="date"
              value={venue.expires ? format(venue.expires, "yyyy-MM-dd") : ""}
              defaultValue={
                venue.expires ? format(venue.expires, "yyyy-MM-dd") : ""
              }
              onChange={(e) => handleDateChange(e.target.value, index)}
              placeholder="Select Date Expires"
            />
          </div>
        </div>
        <div className="container-radio-form">
          <label>Days working at venue</label>
          <div className="content-checkbox">
            {options.map((option) => (
              <div key={option.value} className="checkbox-container">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name={option.value}
                    checked={(venue.days ?? []).includes(option.value)}
                    onChange={(e) =>
                      handleDayChange(option.value, e.target.checked)
                    }
                    className="checkbox-input"
                  />
                  <span className="checkmark"></span>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderDynamicVenueFields = () => {
    return venues.map(renderVenueFields);
  };

  const [venueIds, setVenueIds] = useState([]);

  useEffect(() => {
    const getPromotersEdit = async () => {
      try {
        const response = await api.get(`/promoters/${promoterId}`);
        setPromotersListEdit(response.data);

        const venueIds =
          response.data?.venues.map((venue) => venue.venue_id) || [];

        setVenueIds(venueIds);

        setVenues(response.data?.venues || []);
      } catch (error) {
        console.log(`venues get request didn't work. error: ${error}`);
      }
    };
    getPromotersEdit();
  }, [promoterId]);

  useEffect(() => {
    setName(promotersListEdit?.name || "");
    setPhone(promotersListEdit?.phone || "");
    setEmail(promotersListEdit?.email || "");
    setIg(promotersListEdit?.ig || "");

    setImages(promotersListEdit?.photo || "");
  }, [promotersListEdit]);

  const handleImageAdd = useCallback(
    async (events) => {
      if (!promotersListEdit?._id) return;

      const file = events.target.files[0];

      const formData = new FormData();

      formData.append("photo", file);

      const { data } = await api.post(
        `/promoters/photo/${promotersListEdit._id}`,
        formData
      );

      // const recentImageAdded = data.photo;

      // const image = { id: recentImageAdded };

      setImages(data.photo);

      alert("Image uploaded successfully!");
    },
    [promotersListEdit?._id]
  );

  const handleImageRemove = async () => {
    if (!promotersListEdit?._id) return;

    await api.delete(`/promoters/photo/${promotersListEdit._id}`);

    setImages(null);

    alert("Image DELETED successfully!");
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/promoters/${promoterId}`);
      navigate("/promoters/list");
    } catch (err) {
      console.log(`failed to delete promoter. this is the error: ${err}`);
    }
  };

  return (
    <main>
      <TopNav />
      <div className="main-container">
        <SideBar />
        <article
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <section className="add-user">
            <div
              className="add-user__top flex-wrap gap-1"
              style={{ justifyContent: "space-between" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Link to="/promoters/list">
                  <img
                    className="add-user__top-icon"
                    src={chevronLeft}
                    alt="chevron pointing left"
                  ></img>
                </Link>
                <h1 className="add-user__top-heading">Edit Promoter</h1>
              </div>

              <button onClick={handleDelete} className="edit-venue__top-button">
                <p className="edit-venue__top-button-text">Delete Promoter</p>
                <img src={bin} alt="white garbage bin"></img>
              </button>
            </div>

            <form className="add-user__form">
              <section className="add-user__form--left">
                <div>
                  <div>
                    <InputForm
                      placeholder="Enter Promoter Name"
                      label="Promoter Name"
                      type="text"
                      id="name"
                      name="name"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      error={errors.name}
                    />
                  </div>

                  <div>
                    <InputForm
                      placeholder="Enter Instagram Handle"
                      label="Instagram Handle"
                      type="text"
                      id="ig"
                      name="ig"
                      value={ig}
                      onChange={(event) => setIg(event.target.value)}
                      error={errors.ig}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <InputForm
                      placeholder="Enter Email"
                      label="Email"
                      type="text"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      error={errors.email}
                    />
                  </div>
                  <div>
                    <InputForm
                      placeholder="Enter Phone Number"
                      label="Phone number"
                      type="text"
                      id="phone"
                      name="phone"
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                      error={errors.phone}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <Image
                      onRemove={handleImageRemove}
                      defaultValue={images}
                      onAdd={handleImageAdd}
                    />
                  </div>
                </div>
                {/* 
                <div>
                  {promotersListEdit?.venues.map((item, index) => (
                    <>
                      <h1>{item._id}</h1>
                    </>
                  ))}
                </div> */}

                <div style={{ flexDirection: "column" }}>
                  {renderDynamicVenueFields()}
                </div>
              </section>
            </form>



            <div className='d-flex align-item-center flex-wrap gap-3 pb-4 pt-2'>
                <Button
                    primaryBtn
                    onClick={handleAddUserSubmit}
                    disabled={loading}
                    label={'Update information'}
                    iconifyIconName="material-symbols:check-rounded"
                />
                <Button
                    className='btn-outline-light'
                    disabled={loading}
                    onClick={handleAddVenue}
                    label='Add Venue + '
                />
            </div>
          </section>
        </article>
      </div>
    </main>
  );
};

export default PromotersEdit;
