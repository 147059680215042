import { useCallback, useMemo, useRef, useState } from "react";
import galleryIcon from "../../assets/icons/gallery.svg";
import trashIcon from "../../assets/icons/trash.svg";
import idGenerator from "../../utils/idGenerator";
import imageExtensions from "image-extensions";

import "./index.scss";

export default function Image({ defaultValue, onAdd, onRemove, onLoad }) {
  const inputRef = useRef(null);

  const [file, setFile] = useState();

  const inputName = useMemo(() => idGenerator(), []);

  const handleFileChange = useCallback(
    async (event) => {
      if (!inputRef.current) return;

      if (!event.target.files) {
        inputRef.current.value = "";
        return;
      }

      const file = event.target.files[0];

      if (!file) {
        inputRef.current.value = "";
        return;
      }

      const { name } = file;
      const extension = name.split(".").at(-1);

      const isImageFile = imageExtensions.some((ext) => ext === extension);

      if (!isImageFile) {
        console.log("This file is not a image!");
        inputRef.current.value = "";
        return;
      }

      setFile(file);
      if (onAdd) onAdd(event);
    },
    [onAdd]
  );

  const selectedImage = useMemo(() => {
    if (!file) return "";

    return URL.createObjectURL(file);
  }, [file]);

  const handleRemoveImage = useCallback(
    (event) => {
      setFile();
      inputRef.current.value = "";
      if (onRemove) onRemove(file);
    },
    [onRemove, file]
  );

  const handleImageLoad = useCallback(() => {
    if (onLoad) onLoad();
  }, [onLoad]);

  return (
    <div id="image">
      {defaultValue && !selectedImage && (
        <img
          src={defaultValue}
          alt="Default file"
          className="image__selected-image"
          onLoad={handleImageLoad}
        />
      )}
      {selectedImage && (
        <img
          src={selectedImage}
          alt="Selected file"
          className="image__selected-image"
          onLoad={handleImageLoad}
        />
      )}

      <input
        type="file"
        name={inputName}
        onChange={handleFileChange}
        ref={inputRef}
      />

      {selectedImage || defaultValue ? (
        <button type="button" onClick={handleRemoveImage}>
          <img src={trashIcon} alt="Remove" />
        </button>
      ) : (
        <img src={galleryIcon} alt="Add" />
      )}
    </div>
  );
}
