import "./AdManagement.scss";

import SideBar from "../../components/SideBar/SideBar";
import TopNav from "../../components/TopNav/TopNav";
import CurrentAds from "../../components/CurrentAds/CurrentAds";
import PastAds from "../../components/PastAds/PastAds";
import { Link } from "react-router-dom";

import upload from "../../assets/images/icons/upload.svg";

import { useEffect, useState } from "react";
import api from "../../services/api";
import CurrentAdvertising from "./CurrentAdvertising/CurrentAdvertising";
import PastAdvertising from "./PastAdvertising/PastAdvertising";
import Button from "../../components/Buttons/Button";

import {Tab, TabsContainer} from '../../utils/tab';

function AdManagement() {
    const [currentTab, setCurrentTab] = useState(true);
    const [pastTab, setPastTab] = useState(false);

    function handleSubNavClick(tab) {
        switch (tab) {
            case "current":
                setPastTab(false);
                setCurrentTab(true);
                break;
            case "past":
                setCurrentTab(false);
                setPastTab(true);
                break;
            default:
                break;
        }
    }

    return (
        <main>
            <TopNav />
            <div className="main-container">
                <SideBar />
                <article
                    style={{ width: "100%", display: "flex", justifyContent: "center", overflow: "hidden" }}
                >
                    <section className="ad-management">
                        <div className="ad-management__top flex-wrap gap-2 pb-3">
                            <h1 className="ad-management__top-heading">Ad Management</h1>

                            <div style={{ display: "flex", gap: "20px" }}>
                                <Button
                                    label="Create new Ad +"
                                    isLink primaryBtn
                                    to={'/ad-management/add'}
                                />
                            </div>
                        </div>
                        
                        <TabsContainer className="mb-2">
                            <Tab
                                active={currentTab}
                                onClick={() => handleSubNavClick("current")}
                            >
                                Current advertising
                            </Tab>
                            <Tab
                                active={pastTab}
                                onClick={() => handleSubNavClick("past")}
                            >
                                Past advertising
                            </Tab>
                        </TabsContainer>


                        {currentTab ? <CurrentAdvertising /> : <PastAdvertising />}
                    </section>
                </article>
            </div>
        </main>
    );
}

export default AdManagement;
