import './Settings.scss';

import shield from '../../assets/images/icons/privacy-settings.svg';
import bell from '../../assets/images/icons/notification-settings.svg';
import terms from '../../assets/images/icons/terms-settings.svg';
import chevronRight from '../../assets/images/icons/chevron-right.svg';

import { NavLink, Outlet, useNavigate, useNavigation } from 'react-router-dom';
import TopNav from '../../components/TopNav/TopNav';
import SideBar from '../../components/SideBar/SideBar';
import { useEffect } from 'react';

function Settings() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('privacy')
    },[])
    return (
        <main>
            <TopNav />
            <div className='main-container'>
                <SideBar />
                <article style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                    <section className='settings'>
                        <h1 className='settings__heading'>
                            Settings
                        </h1>
                        <section className='settings__content'>
                            <div className='settings__content-nav'>
                                <NavLink to='privacy' className='settings__content-nav-link'>
                                    <div className='settings__content-nav-link--right'>
                                        <img src={shield} className='settings__content-nav-link-img' alt='shield with a person in it'></img>
                                        <p className='settings__content-nav-link-text'>
                                            Privacy Settings
                                        </p>

                                    </div>
                                    <img src={chevronRight} className='settings__content-nav-link-img' alt='right chevron'></img>
                                </NavLink>

                                <NavLink to='notifications' className='settings__content-nav-link'>

                                    <div className='settings__content-nav-link--right'>
                                        <img src={bell} className='settings__content-nav-link-img' alt='notification bell'></img>
                                        <p className='settings__content-nav-link-text'>
                                            Notifications
                                        </p>
                                    </div>
                                    <img src={chevronRight} className='settings__content-nav-link-img' alt='right chevron'></img>
                                </NavLink>

                                <NavLink to='terms-and-conditions' className='settings__content-nav-link'>

                                    <div className='settings__content-nav-link--right'>
                                        <img src={terms} className='settings__content-nav-link-img' alt='notepaper'></img>
                                        <p className='settings__content-nav-link-text'>
                                            Terms and Conditions
                                        </p>
                                    </div>

                                    <img src={chevronRight} className='settings__content-nav-link-img' alt='right chevron'></img>

                                </NavLink>
                            </div>
                            <section className='settings__content-outlet'>

                                <Outlet />
                            </section>
                        </section>

                    </section>
                </article>
            </div>

        </main>
    )
}

export default Settings;
