import './BottomTabs.css'
import { Link, useLocation } from "react-router-dom";


// import chevronRight from '../../assets/images/icons/chevron-right.svg';
// @ts-ignore
import moderatorIcon from '../../assets/images/icons/sidebar-icons/user.svg';
// @ts-ignore
import usersIcon from '../../assets/images/icons/sidebar-icons/user-id.svg';
// import venuesIcon from '../../assets/images/icons/sidebar-icons/venue.svg';
// @ts-ignore
import eventsIcon from '../../assets/images/icons/sidebar-icons/events.svg';
// import queriesIcon from '../../assets/images/icons/sidebar-icons/queries.svg';
// import sponsorIcon from '../../assets/images/icons/sidebar-icons/wallet.svg';
// import advertIcon from '../../assets/images/icons/sidebar-icons/adverts.svg';
// import promoIcon from '../../assets/images/icons/sidebar-icons/promotions.svg';
// import settingsIcon from '../../assets/images/icons/sidebar-icons/settings.svg';
// import organizerIcon from '../../assets/images/icons/sidebar-icons/organizer.svg';

interface NavItem {
    link: string;
    icon: string;
    text: string;
  }
  
  const navItems: NavItem[] = [
    { link: "/moderators", icon: moderatorIcon, text: "Moderator" },
    { link: "/users", icon: usersIcon, text: "Users" },
    { link: "/events", icon: eventsIcon, text: "Events" },
  ];


//   const sideBarContent = [
//     {
//         id: 1,
//         avatar: moderatorIcon,
//         text: 'Moderator',
//         url: 'moderators'
//     },
//     // {
//     //     id: 2,
//     //     avatar: usersIcon,
//     //     text: 'Users',
//     //     url: 'users'
//     // },
//     // // {
//     // //     id: 12,
//     // //     avatar: organizerIcon,
//     // //     text: 'Organizer Requests',
//     // //     url: 'organizer-requests'
//     // // },
//     // {
//     //     id: 3,
//     //     avatar: venuesIcon,
//     //     text: 'Business',
//     //     url: 'business'
//     // },
//     // {
//     //     id: 4,
//     //     avatar: eventsIcon,
//     //     text: 'Events',
//     //     url: 'events'
//     // },
//     // {
//     //     id: 5,
//     //     avatar: queriesIcon,
//     //     text: 'Queries',
//     //     url: 'queries'
//     // },
//     // {
//     //     id: 6,
//     //     avatar: sponsorIcon,
//     //     text: 'Guestlist',
//     //     url: 'spotlight'
//     // },
//     // // {
//     // //     id: 7,
//     // //     avatar: sponsorIcon,
//     // //     text: 'Liquor Sponsorships',
//     // //     url: 'liquor'
//     // // },
//     // {
//     //     id: 8,
//     //     avatar: advertIcon,
//     //     text: 'Ad Management',
//     //     url: 'ad-management'
//     // },
//     // // {
//     // //     id: 9,
//     // //     avatar: promoIcon,
//     // //     text: 'Promotion',
//     // //     url: 'promotion'
//     // // },
//     // {
//     //     id: 10,
//     //     avatar: promoIcon,
//     //     text: 'Promoters',
//     //     url: 'promoters/list'
//     // },
//     // {
//     //     id: 11,
//     //     avatar: settingsIcon,
//     //     text: 'Settings',
//     //     url: 'settings'
//     // }
// ]

export default function BottomTabs() {

    const location = useLocation()

    const toggleSidebar = () => {
      document.body.classList.toggle('show-sidebar');
    }
    
    return (
        <ul className='bottomTabs d-lg-none'>
            {
                navItems.map(i => (
                    <li key={i.text}
                        style={{
                            background:
                            location.pathname === i.link
                                ? "#6E34FF"
                                : "transparent",
                        }}
                    >
              
                        <Link to={i.link} className="tab-item" >
                            <span className="icon">
                                {/* <span className="iconify" data-icon={i.icon}></span> */}
                                {/* {i.icon} */}

                                <img src={i.icon} />
                            </span>
                            <span>{i.text}</span>
                        </Link>
                    </li>
                ))
            }
            <li>
                <button onClick={toggleSidebar} className="tab-item">
                    <span className="icon"><span className="iconify" data-icon="solar:hamburger-menu-outline"></span></span>
                    <span>More</span>
                </button>
            </li>
        </ul>
    )
}