import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { MdCheck } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import * as Yup from "yup";

import chevronLeft from "../../../../assets/images/icons/chevron-left.svg";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import Image from "../../../../components/Image";
import InputForm from "../../../../components/InputForm/InputForm";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import SelectForm from "../../../../components/SelectForm/SelectForm";
import SideBar from "../../../../components/SideBar/SideBar";
import TextAreaForm from "../../../../components/TextAreaForm";
import TimePicker from "../../../../components/TimePicker/TimePicker";
import TopNav from "../../../../components/TopNav/TopNav";
import api from "../../../../services/api";
import CustomPerVenue from "./CustomPerVenue";
import {
  ContainerAddVenue,
  ContainerButtons,
  ContainerDate,
  ContainerError,
  ContainerForm,
  ContainerHeader,
  ContainerImg,
  ContainerRadioButton,
  ContainerTickets,
  ErrorMessage,
  SwitchContainer,
} from "./styles";
import TotalForDurationEvent from "./TotalForDurationEvent";
import TotalPerVenue from "./TotalPerVenue";
import Button from "../../../../components/Buttons/Button";

const NewBarCrawlEvent = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const [amountOfTickets, setAmountOfTickets] = useState("");
  const [ticketPrice, setTicketPrice] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");

  const [selectedDateEnd, setSelectedDateEnd] = useState(null);
  const [selectedDateStart, setSelectedDateStart] = useState(null);
  const [startHours, setStartHours] = useState();
  const [endHours, setEndHours] = useState();

  const [eventName, setEventName] = useState("");

  const [selectedVenueOption, setSelectedVenueOption] = useState(null);
  const [showContent, setShowContent] = useState(false);
  const [venues, setVenues] = useState([]);
  const [initialVenueAdded, setInitialVenueAdded] = useState(false);
  const [venueOptions, setVenueOptions] = useState([]);

  const [stayTuned, setStayTuned] = useState(false);
  const [isVIPEvent, setIsVIPEvent] = useState(false);

  const [selectedOptionsDrinkVouchers, setSelectedOptionsDrinkVouchers] =
    useState(null);

  const optionsDrinkVouchersQty = [
    { label: 0, value: 0 },
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
  ];

  const [locationOpts, setLocationOpts] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const optionsVenue = [
    {
      label: "Total for the duration of event",
      value: "DURATION",
    },
    { label: "Total per venue", value: "VENUE" },
    { label: "Custom per venue", value: "CUSTOM" },
  ];

  const handleDateEndChange = (date) => {
    setSelectedDateEnd(date);
  };

  const handleDateStartChange = (date) => {
    setSelectedDateStart(date);
  };

  const handleStartTimeChange = ({ value }) => {
    setStartHours(value);
  };

  const handleEndTimeChange = ({ value }) => {
    setEndHours(value);
  };

  const handleAdditionalInformation = (selectedOption) => {
    setAdditionalInformation(selectedOption);
  };

  const validationSchema = Yup.object().shape({
    eventName: Yup.string().required("Event name is required"),
    selectedDateStart: Yup.string().required("Start date is required"),
    selectedDateEnd: Yup.string().required("End date is required"),
    startHours: Yup.string().required("Start hour is required"),
    endHours: Yup.string().required("End hour is required"),
    // amountOfTickets: Yup.number()
    //   .required("Amount of tickets is required")
    //   .typeError("Amount of tickets must be a number")
    //   .min(0, "Amount of tickets cannot be negative"),
    // ticketPrice: Yup.number()
    //   .required("Ticket Price is required")
    //   .typeError("Ticket Price is required must be a number")
    //   .min(0, "Amount of tickets cannot be negative"),
    selectedOptionsDrinkVouchers: Yup.string().required(
      "Drink Vouchers is required"
    ),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setErrors({});

    try {
      setIsSubmitting(true);

      await validationSchema.validate(
        {
          eventName,
          selectedOptionsDrinkVouchers: selectedOptionsDrinkVouchers?.value,
          selectedDateStart,
          selectedDateEnd,
          startHours,
          endHours,
          amountOfTickets,
          ticketPrice,
          location: selectedLocation?.value,
        },
        { abortEarly: false }
      );

      const formattedStartDate = selectedDateStart
        ? format(selectedDateStart, "yyyy-MM-dd")
        : "";
      const formattedEndDate = selectedDateEnd
        ? format(selectedDateEnd, "yyyy-MM-dd")
        : "";

      const newSpotlight = {
        name: eventName,
        vouchersQuantity: selectedOptionsDrinkVouchers?.value,
        voucherRule: selectedVenueOption,
        startDate: formattedStartDate,
        startHour: startHours,
        endDate: formattedEndDate,
        endHour: endHours,
        ticketQuantity: amountOfTickets,
        ticketPrice: ticketPrice,
        description: additionalInformation,
        customVouchersQuantity: "",
        venues: [],
        emptyVenues: stayTuned,
        isVipEvent: isVIPEvent,
        location: selectedLocation?.value,
      };

      const getSelectedValues = (venues) =>
        venues
          .map(({ selectedValue }) => selectedValue?.value)
          .filter((value) => value != null);

      const getCustomVouchersQuantity = (venues) =>
        venues.reduce((acc, { voucherQuantity, selectedValue }) => {
          if (voucherQuantity && selectedValue) {
            acc[selectedValue.value] = voucherQuantity.value;
          }
          return acc;
        }, {});

      const isValidCustomVouchersQuantity = (venues) =>
        venues.every(
          ({ voucherQuantity, selectedValue }) =>
            voucherQuantity && selectedValue?.value != null
        );

      const hasValidSelectedValues = (venues) =>
        venues.every(({ selectedValue }) => selectedValue?.value != null);

      if (
        selectedVenueOption === "DURATION" ||
        selectedVenueOption === "VENUE"
      ) {
        if (!hasValidSelectedValues(venues)) {
          throw new Error("Please fill in all required fields.");
        }
        newSpotlight.venues = getSelectedValues(venues);
        delete newSpotlight.customVouchersQuantity;
      } else if (selectedVenueOption === "CUSTOM") {
        if (!isValidCustomVouchersQuantity(venues)) {
          throw new Error("Please fill in all required fields.");
        }

        newSpotlight.customVouchersQuantity = getCustomVouchersQuantity(venues);
        newSpotlight.venues = getSelectedValues(venues);
      }

      console.log(newSpotlight);

      const formData = new FormData();

      Object.keys(newSpotlight).forEach((key, value) => {
        if (key === "customVouchersQuantity") {
          Object.entries(newSpotlight[key]).forEach(([key, value]) => {
            formData.append(`customVouchersQuantity[${key}]`, value);
          });
        } else if (key === "venues") {
          newSpotlight[key].forEach((item) => {
            formData.append(`${key}[]`, item);
          });
        } else {
          formData.append(key, newSpotlight[key]);
        }
      });

      if (barCrawlImages && barCrawlImages instanceof File) {
        formData.append("image", barCrawlImages);
      }

      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }

      console.log(formData);

      await api.post("/bar-crawl", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: { offset: new Date().getTimezoneOffset() },
      });

      // setLoading(false);
      setIsSubmitting(false);
      toast.success("New Bar Crawl Created Successfully!");
      setErrors({});
      navigate("/events");
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) {
        toast.error(`${error.response.data.message}`);
      } else {
        toast.error(`${error}`);
      }

      if (error instanceof Yup.ValidationError) {
        const formattedErrors = error.inner.reduce((errorObj, currentError) => {
          return {
            ...errorObj,
            [currentError.path]: currentError.message,
          };
        }, {});

        console.log(formattedErrors);
        setErrors(formattedErrors);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVenueOptionChange = (selectedOption) => {
    setSelectedVenueOption(selectedOption);
    setShowContent(false);
    setVenues([]);
    setInitialVenueAdded(false);
  };

  const handleAddVenueClick = () => {
    if (selectedVenueOption) {
      setVenues([...venues, { id: v4() }]);
      setInitialVenueAdded(true);
      setShowContent(true);
    }
  };

  const handleAddNewVenue = () => {
    if (venues.length < 10) {
      setVenues([...venues, { id: v4() }]);
    }
  };

  const handleRemoveVenue = (id) => {
    setVenues(venues.filter((venue) => venue.id !== id));
  };

  useEffect(() => {
    const LoadVenuesAll = async () => {
      try {
        const response = await api.get("/venues", {
          params: {
            records: 999999999,
          },
        });

        const venues = response.data.data;

        const formattedVenues = venues.map((venue) => ({
          label: venue.name,
          value: venue._id,
        }));

        setVenueOptions(formattedVenues);
      } catch (error) {
        console.error("Error loading venues:", error);
      }
    };

    LoadVenuesAll();
  }, []);

  useEffect(() => {
    async function fetchLocationOptions() {
      const { data } = await api.get('/filters');

      const locationOpts = data.location.map(item => ({
        label: item,
        value: item,
      }));

      setLocationOpts(locationOpts);
    }

    fetchLocationOptions();
  }, []);

  const handleSelectedVenue = (venueId, selectedValue) => {
    setVenues((prevVenues) =>
      prevVenues.map((venue) =>
        venue.id === venueId ? { ...venue, selectedValue } : venue
      )
    );
  };

  const handleVoucherQuantityChange = (venueId, quantity) => {
    setVenues((prevVenues) =>
      prevVenues.map((venue) =>
        venue.id === venueId ? { ...venue, voucherQuantity: quantity } : venue
      )
    );
  };

  const [barCrawlImages, setBarCrawlImages] = useState(null);

  console.log(barCrawlImages);

  const handleImageAdd = useCallback((event) => {
    const file = event.target.files[0];

    setBarCrawlImages(file);
  }, []);

  const handleImageRemove = useCallback(() => {
    setBarCrawlImages(null);
  }, []);

  const handleTicketPriceChange = (event) => {
    const { value } = event.target;

    const numericValue = value.replace(/[^0-9.]/g, "");

    const [integerPart, decimalPart] = numericValue.split(".");

    const formattedValue = decimalPart
      ? `${integerPart}.${decimalPart.slice(0, 2)}`
      : integerPart;

    setTicketPrice(formattedValue);

    if (formattedValue !== numericValue) {
      setErrors({
        ticketPrice: "Ticket price cannot have more than two decimal places.",
      });
    } else {
      setErrors({ ticketPrice: "" });
    }
  };

  const handleAmountOfTicketsChange = (event) => {
    const { value } = event.target;

    const numericValue = value.replace(/[^0-9]/g, "");

    const integerValue = parseInt(numericValue, 10);

    const limitedValue = integerValue > 999999 ? 999999 : integerValue;

    setAmountOfTickets(limitedValue);

    if (integerValue === 999999) {
      setErrors({
        amountOfTickets: "Unlimited",
      });
    } else if (value.includes(".")) {
      setErrors({
        amountOfTickets: "Amount of tickets cannot have decimal places.",
      });
    } else {
      setErrors({
        amountOfTickets: "",
      });
    }
  };

  const handleChangeStayTuned = useCallback(() => {
    setVenues([]);
    setStayTuned(oldState => !oldState);
    setInitialVenueAdded(false);
    setShowContent(false);
  }, []);

  const handleChangeVIPEvent = useCallback(() => {
    setIsVIPEvent(oldState => !oldState)
  })

  return (
    <>
      <main>
        <TopNav />
        <div className="main-container">
          <SideBar />
          <article
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <section className="add-user">
              <ContainerHeader>
                <Link to={`/events`}>
                  <img src={chevronLeft} alt="" />
                </Link>

                <h1>New Featured Event</h1>
              </ContainerHeader>

              <ContainerForm onSubmit={handleFormSubmit}>
                <div>
                  <div>
                    <InputForm
                      id="eventName"
                      placeholder="Enter event name"
                      label="Event name"
                      value={eventName}
                      onChange={(event) => {
                        setEventName(event?.target.value);
                      }}
                      error={errors.eventName}
                    />
                  </div>

                  <div>
                    <SelectForm
                      label="Drink Voucher Qty"
                      options={optionsDrinkVouchersQty}
                      value={selectedOptionsDrinkVouchers}
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setSelectedOptionsDrinkVouchers(selectedOption);
                        }
                      }}
                      error={errors.selectedOptionsDrinkVouchers}
                      isDisabled={selectedVenueOption === "CUSTOM"}
                    />
                  </div>
                </div>

                <ContainerRadioButton>
                  <RadioButton
                    options={optionsVenue}
                    onChange={handleVenueOptionChange}
                    error={errors.selectedVenueOption}
                  />
                </ContainerRadioButton>

                <SwitchContainer>
                  <div>
                    <button type="button" onClick={handleChangeStayTuned}>Stay tuned!</button>

                    <div
                      className={`slide-button ${stayTuned ? "checked" : ""}`}
                      onClick={handleChangeStayTuned}
                    >
                      <div className="slider"></div>
                    </div>
                  </div>

                  <SelectForm
                    label="Location"
                    options={locationOpts}
                    value={selectedLocation}
                    onChange={setSelectedLocation}
                    isDisabled={!stayTuned}
                  />
                </SwitchContainer>
                

                {!initialVenueAdded && (
                  <ContainerAddVenue>
                    <button type="button" onClick={handleAddVenueClick} disabled={stayTuned}>
                      Add Venue
                    </button>
                  </ContainerAddVenue>
                )}

                {showContent && selectedVenueOption === "DURATION" && (
                  <>
                    <TotalForDurationEvent
                      venues={venues}
                      handleSelectedVenue={handleSelectedVenue}
                      handleRemoveVenue={handleRemoveVenue}
                      handleAddNewVenue={handleAddNewVenue}
                      error={errors.venues}
                      venueOptions={venueOptions}
                      disabled={stayTuned}
                    />
                  </>
                )}

                {showContent && selectedVenueOption === "VENUE" && (
                  <>
                    <TotalPerVenue
                      selectedOptionsDrinkVouchers={
                        selectedOptionsDrinkVouchers
                      }
                      venues={venues}
                      handleSelectedVenue={handleSelectedVenue}
                      handleRemoveVenue={handleRemoveVenue}
                      handleAddNewVenue={handleAddNewVenue}
                      handleVoucherQuantityChange={handleVoucherQuantityChange}
                      error={errors.selectedEvents}
                      venueOptions={venueOptions}
                      disabled={stayTuned}
                    />
                  </>
                )}

                {showContent && selectedVenueOption === "CUSTOM" && (
                  <>
                    <CustomPerVenue
                      venues={venues}
                      selectedOptionsDrinkVouchers={
                        selectedOptionsDrinkVouchers
                      }
                      handleSelectedVenue={handleSelectedVenue}
                      handleRemoveVenue={handleRemoveVenue}
                      handleAddNewVenue={handleAddNewVenue}
                      handleVoucherQuantityChange={handleVoucherQuantityChange}
                      error={errors.selectedEvents}
                      venueOptions={venueOptions}
                      disabled={stayTuned}
                    />
                  </>
                )}

                <ContainerDate>
                  <div>
                    <h1>Start</h1>

                    <div>
                      <CustomDatePicker
                        label="Date"
                        selectedDate={selectedDateStart}
                        onChange={handleDateStartChange}
                        error={errors.selectedDateStart}
                      />

                      <TimePicker
                        label="Time"
                        error={errors.startHours}
                        onChangeTime={handleStartTimeChange}
                      />
                    </div>
                  </div>

                  <div>
                    <h1>End</h1>

                    <div>
                      <CustomDatePicker
                        label="Date"
                        selectedDate={selectedDateEnd}
                        onChange={handleDateEndChange}
                        error={errors.selectedDateEnd}
                      />

                      <TimePicker
                        label="Time"
                        error={errors.endHours}
                        onChangeTime={handleEndTimeChange}
                      />
                    </div>
                  </div>
                </ContainerDate>

                <ContainerTickets>
                  <div>
                    <InputForm
                      type="number"
                      id="amountOfTickets"
                      label="Amount of tickets"
                      value={amountOfTickets}
                      onChange={handleAmountOfTicketsChange}
                      error={errors.amountOfTickets}
                    />

                    <InputForm
                      type="number"
                      id="ticketPrice"
                      label="Ticket price"
                      value={ticketPrice}
                      onChange={handleTicketPriceChange}
                      error={errors.ticketPrice}
                    />
                  </div>

                  <div>
                    <TextAreaForm
                      onChange={handleAdditionalInformation}
                      value={additionalInformation}
                      label="Additional information"
                      placeholder="Description of the venue"
                    />
                  </div>
                </ContainerTickets>

                <ContainerImg>
                  <Image
                    onAdd={handleImageAdd}
                    onRemove={() => handleImageRemove()}
                  />
                </ContainerImg>

                <SwitchContainer>

                    <div>
                      <button type="button" onClick={handleChangeVIPEvent}>Set as VIP Event</button>

                      <div
                        className={`slide-button ${isVIPEvent ? "checked" : ""}`}
                        onClick={handleChangeVIPEvent}
                      >
                        <div className="slider"></div>
                      </div>
                    </div>
                </SwitchContainer>


                <div className="pt-3 d-flex flex-wrap gap-3">
                  <Button
                    primaryBtn
                    type="submit"
                    disabled={isSubmitting}
                    label="Save Featured Event"
                    iconifyIconName="material-symbols:check-rounded"
                  />
                  <Button 
                    className="btn-outline-light"
                    isLink
                    to="/events"
                    label="Cancel"
                  />
                </div>

              </ContainerForm>
            </section>
          </article>
        </div>
      </main>
    </>
  );
};

export default NewBarCrawlEvent;
