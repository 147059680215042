import styled from "styled-components";

const backgroundBlack = "#020204";
const backgroundBlue = "#151B6D";
const backgroundDarkBlue = "#170D36";

export const Container = styled.div`
  background: linear-gradient(
    0deg,
    ${backgroundBlack} 0%,
    ${backgroundDarkBlue} 70%,
    ${backgroundBlue} 100%
  );

  background: #121212;
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
`;

export const ContainerInputs = styled.div`
  display: flex;

  flex-direction: column;

  max-width: 358px;

  > div:nth-child(1) {
    margin-bottom: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      max-width: 240px;
      height: auto;
    }
  }

  > div:nth-child(2) {
    width: 358px;

    > button {
      margin: 15px 0px 21px 0px;

      background: linear-gradient(271.14deg, #7229f5 1.5%, #0a3fd6 98.6%);
      border-radius: 8px;

      padding: 16px 32px 16px 32px;

      color: #ffffff;

      font-size: 18px;

      font-weight: 600;

      border: none;

      width: 358px;
    }
  }

  .container-input { 
    margin: 16px 0;

    > input { 
      height: 54px;
      padding: 16px;
    }
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    color: red;
  }
`;

export const ContainerForgotPassword = styled.div`
  display: flex;
  justify-content: center;

  flex-direction: column;
  align-items: center;

  > button {
    border: none;
    background: transparent;

    color: #fff;

    font-size: 14px;
    
    font-weight: 700;

    line-height: 16.8px;
  }
`;

export const ContainerCreateAccount = styled.div`
  display: flex;

  flex-direction: column;

  justify-content: center;
  align-items: center;

  text-align: center;

  gap: 10px;

  > a {
    color: #fff;

    text-decoration: none;

    font-size: 16px;

    width: 100%;
  }
`;
