import React, { ReactNode, useState } from "react";
import { Link, LinkProps } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

type hasLink = {
  isLink: true
} & LinkProps

type notLink = {
  isLink: false | undefined
}

type CombinedLinkProps = hasLink | notLink

type ButtonProps = {
  backgroundColor?: string;
  disabledBackgroundColor?: string;
  icon?: ReactNode;
  iconifyIconName?: string;
  iconPosition?: 'left' | 'right';
  onClick?: () => void | Promise<void>;
  children?: React.ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  showLoading?:boolean;
  gradientButton?:boolean;
  textColor?: string,
  className?:string,
  primaryBtn?:boolean,
  label?:string
} & CombinedLinkProps

const Button: React.FC<ButtonProps> = ({
  backgroundColor,
  icon,
  iconifyIconName,
  iconPosition = 'right',
  onClick,
  children,
  disabled,
  disabledBackgroundColor,
  gradientButton,
  textColor,
  showLoading,
  className,
  primaryBtn,
  label,
  type = 'button',
  isLink,
  
  ...rest
}) => {

  const Icon = () => {
    if ((showLoading)){
      return (<TailSpin color="inherit" width={20} height={20} />)
    }

    if ((iconifyIconName && !showLoading)) {
      return (<span><span className="iconify icon" data-icon={iconifyIconName}></span></span>)
    }
    
    if ((icon && !showLoading)) {
      return (<span><span className="icon">{icon}</span></span>)
    }
    
    return ''
  }

  if (isLink) {
    const props = rest as LinkProps;

    return(
      <Link
        className={`btn ${primaryBtn ? 'btn-primary' : ''} ${className}`}
        {...props}
      >
        {
          iconPosition === 'left' ? (<Icon />) : ''
        }
        {label}
        {children}
        {
          iconPosition === 'right' ? (<Icon />) : ''
        }
      </Link>
    )
  }

  const props = rest as LinkProps;

  return (
    <button
            className={`btn ${primaryBtn ? 'btn-primary' : ''} ${className}`}
            style={{
            background:
                disabled && disabledBackgroundColor
                ? disabledBackgroundColor
                : backgroundColor ? backgroundColor : (gradientButton ? 'linear-gradient(90deg, #7229f5, #0a3fd6)' : ''),
            cursor: disabled ? "not-allowed" : "pointer",
            color: textColor
            }}
            // @ts-ignore
            onClick={onClick} type={type}
            disabled={disabled || showLoading}
            {...props}
    >
        {
          iconPosition === 'left' ? (<Icon />) : ''
        }
        {label}
        {children}
        {
          iconPosition === 'right' ? (<Icon />) : ''
        }
    </button>
  );
};

export default Button;
