import React, { useState } from "react";
import "./InputForm.scss";
import styled from "styled-components";
import { FaEye, FaEyeSlash } from "react-icons/fa";


const ShowPasswordIcon = styled.span`
  cursor: pointer;
  color: #ffffff;
  position: absolute;
  right: 10px;
  top: 72%;
  transform: translateY(-70%);
`;


const InputForm = (props) => {
    const { id, value, onChange, placeholder, label = "", validationSchema, error, type = "text" | "password"
    } = props;


    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validateValue = async (inputValue) => {
        if (!validationSchema) return;

        try {
            await validationSchema.validateAt(id, { [id]: inputValue });
        } catch (err) {
        }
    };

    const handleChange = (event) => {
        const { value } = event.target;
        onChange(event);
        validateValue(value);
    };

    return (
        <div className="container-input">
            <div>
                {label && <label htmlFor={id} >{label}</label>}
                {error && <p className="error-message">{error}</p>}
            </div>
            <input
                id={id}
                type={type === "password" ? (showPassword ? "text" : "password") : type}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                className={error ? "error" : ""}
            />

            {id === "password" && (
                <ShowPasswordIcon onClick={togglePasswordVisibility}>
                    {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                </ShowPasswordIcon>
            )}
        </div>
    );
};

export default InputForm;
