
import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  justify-content: stretch;

  background: rgb(9, 9, 9);
  border-radius: 1000px;
  overflow: auto;
  height: 50px;
`;

export const Tab = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  text-decoration: none;
  background: ${props => (props as any)?.active ? 'var(--primary-color)' : 'none'};
  white-space: nowrap;
  cursor: pointer;

      border-radius: 1000px;
    padding: 12px;
    font-size: 15px;

    flex: 1;

  > p  {
    font-size: 15px;

  }
`;