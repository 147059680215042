import "../../styles/CalendarStyles.css";
import "./EditQueries.scss";

import { format } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import checkMark from "../../assets/images/icons/check-mark.svg";
import chevronLeft from "../../assets/images/icons/chevron-left.svg";
import bin from "../../assets/images/icons/delete.svg";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import FormDatePicker from "../../components/FormDatePicker/FormDatePicker";
import InputForm from "../../components/InputForm/InputForm";
import SelectForm from "../../components/SelectForm/SelectForm";
import SideBar from "../../components/SideBar/SideBar";
import TopNav from "../../components/TopNav/TopNav";
import api from "../../services/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../components/Buttons/Button";

const EditQueries = () => {
    const { queriesId } = useParams();
    const navigate = useNavigate();

    const [venue, setVenue] = useState();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [rating, setRating] = useState();
    const [text, setText] = useState();
    const [answer, setAnswer] = useState();

    const [queries, setQueries] = useState();

    const [errors, setErrors] = useState({});


    useEffect(() => {
        const getQueries = async () => {
            try {
                const response = await api.get(`/supports/${queriesId}`);
                setQueries(response.data);
            } catch (err) {
                console.log(`error retrieving Query. this is the error: ${err}`);
            }
        };
        getQueries();
    }, [queriesId]);

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        try {
            const editedUser = {
                answer: answer
            };

            await api.put(`/supports/${queries._id}`, editedUser);

            toast.success("Query sent successfully!");

            navigate("/queries");
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach((err) => {
                    validationErrors[err.path] = err.message;
                });
                toast.error(`Error Edit Query. Please try again, ${error.message}`);
                setErrors(validationErrors);
            } else {
                console.error("Error edit Query:", error);
            }
        }
    };

    const handleDelete = async (id) => {
        try {
            await api.delete(`/supports/${id}`);
            toast.success("Query Delete successfully!");

            navigate("/queries");
        } catch (err) {
            toast.error(`Error Delete Query. Please try again, ${err.message}`);

            console.log(`failed to delete Query. this is the error: ${err}`);
        }
    };

    const handleCancel = async (id) => {
        try {
            await api.patch(`supports/cancel/${id}`)

            toast.success("Query Cancel successfully!");


            navigate("/queries");
        } catch (err) {
            toast.error(`Error Cancel Query. Please try again, ${err.message}`);
        }
    };

    useEffect(() => {
        setName(queries?.user_name);
        setEmail(queries?.user_email);
        setPhoneNumber(queries?.user_phone);
        setVenue(queries?.venue_name)

        setText(queries?.description);

        setAnswer(queries?.answer)
    }, [queries]);

    return (
        <main>
            <TopNav />
            <div className="main-container">
                <SideBar />

                <article
                    style={{ width: "100%", display: "flex", justifyContent: "center" }}
                >
                    <section className="edit-user">
                        <div className="edit-user__top flex-wrap gap-2 mb-3">
                            <div className="edit-user__top-left">
                                <Link to="/queries">
                                    <img
                                        className="edit-user__top-left-icon"
                                        src={chevronLeft}
                                        alt="Left pointing chevron"
                                    ></img>
                                </Link>
                                <h1 className="edit-user__top-left-heading">Editing query</h1>
                            </div>
                            <div>
                                <Button 
                                    className="btn-outline-light"
                                    onClick={() => {
                                        handleDelete(queries._id);
                                    }}
                                    label="Delete Query"
                                    iconifyIconName="mynaui:trash"
                                />
                            </div>
                        </div>

                        <form className="edit-user__form">
                            <div className="edit-user__form-first">
                                <div>
                                    <div>
                                        <InputForm
                                            placeholder=" Name"
                                            label=" Name"
                                            type="text"
                                            id="Name"
                                            name="Name"
                                            value={name}
                                            onChange={(event) => setName(event.target.value)}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <InputForm
                                            placeholder="Email"
                                            label="Email"
                                            type="text"
                                            id="Email"
                                            name="Email"
                                            value={email}
                                            onChange={(event) => setEmail(event.target.value)}
                                        />
                                    </div>

                                    <div>
                                        <InputForm
                                            placeholder="Phone Number"
                                            label="Phone Number"
                                            type="text"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            onChange={(event) => setPhoneNumber(event.target.value)}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="container-input">
                                        <label>Text</label>
                                        <textarea
                                            style={{ width: "100%" }}
                                            rows={4}
                                            id="Description"
                                            name="about"
                                            value={text}
                                            onChange={(event) => setText(event.target.value)}
                                            placeholder="Enter about"
                                            className="venue-info__form-group-about-input"
                                        />
                                    </div>
                                </div>

                                <h1 style={{ color: "#fff" }}>Notes</h1>
                                <div>
                                    <div className="container-input">
                                        <label>Answer</label>
                                        <textarea
                                            style={{ width: "100%" }}
                                            rows={4}
                                            id="Answer"
                                            name="Answer"
                                            value={answer}
                                            onChange={(event) => setAnswer(event.target.value)}
                                            placeholder="Enter Answer"
                                            className="venue-info__form-group-about-input"
                                        />
                                    </div>
                                </div>


                            </div>
                        </form>



                        <div className="pt-3 d-flex flex-wrap gap-3">
                            <Button
                                primaryBtn
                                onClick={handleFormSubmit}
                                label="Send and resolve"
                                iconifyIconName="material-symbols:check-rounded"
                            />
                            <Button 
                                className="btn-outline-light"
                                onClick={() => {
                                    handleCancel(queries._id);
                                }}
                                label="Cancel"
                            />
                        </div>
                    </section>
                </article>
            </div>
        </main>
    );
};

export default EditQueries;
