const CalculateAge = (dataNascimento) => {
    if (!dataNascimento) {
        return "Invalid date of birth";
    }

    const dataAtual = new Date();
    const dataNascimentoArray = dataNascimento.split("-");
    const anoNascimento = parseInt(dataNascimentoArray[0]);
    const mesNascimento = parseInt(dataNascimentoArray[1]) - 1;
    const diaNascimento = parseInt(dataNascimentoArray[2]);

    let idade = dataAtual.getFullYear() - anoNascimento;

    if (dataAtual.getMonth() < mesNascimento || (dataAtual.getMonth() === mesNascimento && dataAtual.getDate() < diaNascimento)) {
        idade--;
    }

    return idade;
};

export default CalculateAge;
