import './App.scss';
import './styles/CalendarStyles.css';
import './styles/global.scss';

import { useEffect, useState, useRef } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import NotificationSettings from './components/NotificationSettings/NotificationSettings';
import PrivacySettings from './components/PrivacySettings/PrivacySettings';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import { auth } from './firebase/firebaseConfig';
import AddAd from './pages/AddAd/AddAd';
import AddEvent from './pages/AddEvent/AddEvent';
import AddUsers from './pages/AddUsers/AddUsers';
import AddVenue from './pages/AddVenue/AddVenue';
import AdManagement from './pages/AdManagement/AdManagement';
import EditAd from './pages/EditAd/EditAd';
import EditEvent from './pages/EditEvent/EditEvent';
import EditUsers from './pages/EditUsers/EditUsers';
import EditVenue from './pages/EditVenue/EditVenue';
import Events from './pages/Events/Events';
import LiquorSponsor from './pages/LiquorSponsor/LiquorSponsor';
import Moderators from './pages/Moderators/Moderators';
import Promotion from './pages/Promotion/Promotion';
import Queries from './pages/Queries/Queries';
import Settings from './pages/Settings/Settings';
import SignIn from './pages/SignIn/SignIn';
import Sponsorship from './pages/Sponsorship/Sponsorship';
import UserProfile from './pages/UserProfile/UserProfile';
import Users from './pages/Users/Users';
import OrganizerRequests from './pages/OrganizerRequests/OrganizerRequests';
import OrganizerRequestDetails from './pages/OrganizerRequestDetails/OrganizerRequestDetails'
import Venues from './pages/Venues/Venues';
import SpotlightEvent from './pages/SpotlightEvent/SpotlightEvent'

import PromotersList from './pages/Promoters/List/PromotersList';
import PromotersNew from './pages/Promoters/New/PromotersNew';
import PromotersEdit from './pages/Promoters/Edit/PromotersEdit';
import EditQueries from './pages/EditQueries/EditQueries';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from './services/api';
import ProtectedRoutes from './routes/ProtectedRoutes';
import TwoFactorAuth from './pages/TwoFactorAuth';
import AcceptVenueRequest from './pages/VenueRequest';
import NewBarCrawlEvent from './pages/Events/NewBarCrawlEvent/New';
import EditBarCrawlEvent from './pages/Events/NewBarCrawlEvent/Edit';
import ReviewGuestList from './pages/Events/ReviewGuestList';
import ReviewTicketList from './pages/Events/ReviewTicketList';
import EditTicketSales from "./components/TicketSales/Edit";
import MembershipRequestDetails from './pages/MembershipRequestDetails/MembershipRequestDetails';
import OrganizerVenuesRequestDetails from './pages/OrganizerVenuesRequestDetails/OrganizerVenuesRequestDetails';

function App() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const idToken = await user.getIdToken();
                    api.defaults.headers.authorization = `Bearer ${idToken}`;
                    setUser(user);
                } catch (error) {
                    console.error('Erro ao obter idToken:', error);
                }
            } else {
                setUser(null);
            }
            setLoading(false);
        });

        const renewTokenInterval = setInterval(async () => {
            if (user) {
                try {
                    const idToken = await user.getIdToken(true);
                    api.defaults.headers.authorization = `Bearer ${idToken}`;
                    setUser(user);

                } catch (error) {
                    console.error('Erro ao renovar token:', error);
                }
            }
        }, 50000);

        return () => {
            unsubscribe();
            clearInterval(renewTokenInterval);
        };
    }, [user]);



    if (loading) {
        return null;
    }

    return (
        <BrowserRouter>
            <ToastContainer />
            <Routes>
                <Route path="/signin" element={<SignIn />} />

                <Route path='/signin' element={<SignIn />} />

                <Route path="/two-factor-auth" element={<TwoFactorAuth />} />

                {
                    user
                        ?
                        <Route
                            element={
                                <ProtectedRoutes>
                                    <Outlet />
                                </ProtectedRoutes>
                            }
                        >

                            {/* <Route path="/" element={<Moderators />} /> */}
                            <Route path="/" element={<Users />} />
                            <Route path="/moderators" element={<Moderators />} />
                            {/* <Route path="/organizer-requests" element={<OrganizerRequests />} /> */}
                            <Route path="/organizer-requests/:userID" element={<OrganizerRequestDetails />} />
                            {/* <Route path="/organizer-requests" element={<OrganizerRequests />} /> */}
                            <Route path="/organizer-venue-requests/:userID" element={<OrganizerVenuesRequestDetails />} />
                            <Route path="/membership-requests/:userID" element={<MembershipRequestDetails />} />
                            <Route path="/users" element={<Users />} />
                            <Route path="/users/add" element={<AddUsers />} />
                            <Route path="/users/edit/:userID" element={<EditUsers />} />
                            <Route path="/users/:userID" element={<UserProfile />} />
                            <Route path="/business" element={<Venues />} />
                            <Route path="/business/add" element={<AddVenue />} />
                            <Route
                                path="/business/edit/:venueId"
                                element={<EditVenue />}
                            />
                            <Route path="/spotlight/event/:eventId" element={<SpotlightEvent />} />
                            <Route path="/events" element={<Events />} />
                            <Route path='/events/add' element={<AddEvent />} />
                            <Route path='/events/edit/:eventID' element={<EditEvent />} />

                            <Route
                                path="/events/review-guestlist/:eventsId"
                                element={<ReviewGuestList />}
                            />
                            <Route
                                path="/events/review-tickets/:eventsId"
                                element={<ReviewTicketList />}
                            />

                            <Route path="/queries" element={<Queries />} />
                            <Route path="/promoters/list" element={<PromotersList />} />
                            <Route path="/promoters/new" element={<PromotersNew />} />
                            <Route path="/promoters/edit/:promoterId" element={<PromotersEdit />} />
                            <Route path="/spotlight" element={<Sponsorship />} />
                            <Route path='/liquor' element={<LiquorSponsor />} />
                            <Route path='/ad-management' element={<AdManagement />} />
                            <Route path='/ad-management/edit/:adID' element={<EditAd />} />
                            <Route path='/ad-management/add' element={<AddAd />} />
                            <Route path="/promotion" element={<Promotion />} />
                            <Route path="/queries/list" element={< Queries />} />
                            <Route path="/queries/edit/:queriesId" element={< EditQueries />} />
                            <Route path="/venue-requests/view/:venueRequestId" element={<AcceptVenueRequest />} />

                            <Route path="/bar-crawl-event/new" element={<NewBarCrawlEvent />} />
                            <Route path="/bar-crawl-event/edit/:eventId" element={<EditBarCrawlEvent />} />
                            <Route path="/ticket-sales/edit/:ticketId" element={<EditTicketSales />} />


                            <Route path="/settings" element={<Settings />}>
                                <Route path="privacy" element={<PrivacySettings />} />
                                <Route
                                    path="notifications"
                                    element={<NotificationSettings />}
                                />
                                <Route
                                    path="terms-and-conditions"
                                    element={<TermsAndConditions />}
                                />
                            </Route>
                        </Route>

                        :

                        <Route path="*" element={<Navigate to="/signin" />} />
                }


            </Routes>
        </BrowserRouter>
    );
}

export default App;
