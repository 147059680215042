import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebaseConfig";
import { isAfter } from "date-fns";

export default function ProtectedRoutes({ children }) {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);




  useEffect(() => {
    if (window.innerWidth < 996) {
        document.body.classList.remove('show-sidebar')
    }

    const siteMain = document.body;

    siteMain?.scrollTo({
        top: 0,
        left: 0,
        behavior:'instant'
    })

    window.scrollTo({
        top: 0,
        left: 0,
        behavior:'instant'
    })
  }, [pathname]);

  useEffect(() => {
    // const twoFactorAuth = localStorage.getItem("2FA@ZonaAdmin");

    // if (!twoFactorAuth) {
    //   auth.signOut().then(() => {
    //     setVerified(null);
    //     setLoading(false);
    //   });
    //   return;
    // }

    // const { verified, expires } = JSON.parse(twoFactorAuth);

    // if (isAfter(Date.now(), expires)) {
    //   auth.signOut().then(() => {
    //     setVerified(null);
    //     setLoading(false);
    //   });
    //   return;
    // }

    // setVerified(verified);
    // setLoading(false);

    setLoading(false);
    setVerified(true);
  }, [navigate]);

  if (loading) return null;

  if (verified === null) return <Navigate to="/signin" />;

  // if (!verified) return <Navigate to="/two-factor-auth" />;

  return children;
}
