import React, { useState, useEffect } from "react";
import "./RadioButton.scss";

function RadioButton({ options, onChange, error, title, value }) {
  const [selectedOption, setSelectedOption] = useState(value);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    onChange(option);
  };

  return (
    <div className="container-radio">
      <div>
        {title && (
          <div className="content-error">
            <h1>{title}</h1>
            {error && <p className="error-message">{error}</p>}
          </div>
        )}

        <div className="content-radio">
          {options.map((option) => (
            <label key={option.value} className="radio-container">
              <div style={{ display: "flex", alignItems: "center" }}>
                {option.label}
              </div>
              <input
                type="radio"
                name="radio-option"
                checked={selectedOption === option.value}
                onChange={() => handleOptionChange(option.value)}
              />
              <span className="checkmark"></span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RadioButton;
