export default function idGenerator(length = 6) {
  const id = [];
  const lettersAndSymbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%&';
  const lettersAndSymbolsSize = lettersAndSymbols.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * (lettersAndSymbolsSize - 1));
    const randomLetterOrSymbol = lettersAndSymbols.charAt(randomIndex);

    id.push(randomLetterOrSymbol);
  }

  return id.join('');
}