import "./PromotersList.scss";
import { useEffect, useState } from "react";
import SideBar from "../../../components/SideBar/SideBar";
import TopNav from "../../../components/TopNav/TopNav";
import api from "../../../services/api";
import TableColumn from "../../../components/TableColumn/TableColumn";
import { useNavigate } from "react-router-dom";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaRegTimesCircle, FaCheck } from "react-icons/fa";
import Button from "../../../components/Buttons/Button";

function PromotersList() {
  const navigate = useNavigate();
  const [promoter, setPromoter] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("ListCurrentPagePromoters")) || 1
  );
  const records = 10;

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("null");

  useEffect(() => {
    const getPromoter = async (page) => {
      try {
        const response = await api.get("/promoters", {
          params: {
            page: page,
            records: 10,
            sort_column: sortBy,
            sort: sortDirection,
          },
        });

        const itensTableColumns = response.data.data.map((promoter) => ({
          "": (
            <div>
              <MdOutlineRemoveRedEye
                onClick={() => navigate(`/promoters/edit/${promoter._id}`)}
                size={30}
                color="rgba(167, 41, 245, 1)"
                style={{ cursor: "pointer" }}
              />
            </div>
          ),
          Name: promoter?.name,
          Instagram: `@${promoter.ig}`,
          Phone: promoter.phone,
          Email: promoter.email,
          promoterId: promoter._id,
        }));

        setPromoter(itensTableColumns);
        setTotalPages(response.data.total);
      } catch (error) {
        console.log(`Promoter get request didn't work. error: ${error}`);
      }
    };

    getPromoter(currentPage);
  }, [navigate, currentPage, sortBy, sortDirection]);

  const columns = ["Name", "Instagram", "Phone", "Email", ""];

  const sortableColumns = ["Name"];

  const getStatusColor = (status) => {
    console.log(status);
    switch (status) {
      case "Declined":
        return "rgba(231, 76, 60, 0.1)";
      case "Active":
        return "rgba(46, 204, 113, 0.1)";
      case "New":
        return "rgba(241, 196, 15, 0.1)";
      case "Expired":
        return "rgba(231, 76, 60, 0.1)";
      default:
        return "inherit";
    }
  };
  const fetchAllData = async () => {
    try {
      const response = await api.get(`/promoters`, {
        params: {
          records: 9999999999,
        },
      });

      return response.data.data.map((promoter) => ({
        Name: promoter?.name,
        Instagram: `@${promoter.ig}`,
        Phone: promoter.phone,
        Email: promoter.email,
        promoterId: promoter._id,
      }));
    } catch (error) {
      console.log(`Error fetching all data: ${error}`);
      return [];
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("ListCurrentPagePromoters", page);
  };

  useEffect(() => {
    const storedPage = localStorage.getItem("ListCurrentPagePromoters");

    setCurrentPage(Number(storedPage) || 1);
  }, []);

  const sortColumnsMapping = {
    Name: "name",
  };

  const handleSort = (columnName) => {
    const backendColumnName = sortColumnsMapping[columnName];
    if (sortBy === backendColumnName) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(backendColumnName);
      setSortDirection("asc");
    }
  };

  return (
    <main>
      <TopNav />
      <div className="main-container">
        <SideBar />
        <article
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <section className="sponsorship">
            <TableColumn
              title="Promoter List View"
              columns={columns}
              data={promoter}
              sortableColumns={sortableColumns}
              hasExport={true}
              hasSearch={false}
              fetchAllData={fetchAllData}
              getStatusColor={getStatusColor}
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              handlePageChange
              records={records}
              customButton={
                <Button primaryBtn onClick={() => navigate("/promoters/new")}>
                  Add new promoter +
                </Button>
              }
              onSort={handleSort}
            />
          </section>
        </article>
      </div>
    </main>
  );
}

export default PromotersList;
