import "./NotificationSettings.scss";
import { auth } from "../../firebase/firebaseConfig";
import api from "../../services/api";
import { useCallback, useState, useEffect } from "react";

function NotificationSettings() {
    const [user, setUser] = useState(null);
    const [isNotifsChecked, setIsNotifsChecked] = useState(false);

    useEffect(() => {
        async function loadUser() {
            const currentUser = auth.currentUser;
            if (currentUser) {
                const { uid } = currentUser;
                const { data } = await api.get(`/users/${uid}`);
                setUser(data.user);
                setIsNotifsChecked(data.user.notifications_enabled);
            }
        }

        loadUser();
    }, []);

    const handleUpdateNotification = useCallback(
        async (checked) => {
            try {
                await api.put(`/users/${user.id}`, { notifications_enabled: checked });
                setIsNotifsChecked(checked);
            } catch (error) {
                console.error("Error updating notification status:", error);
            }
        },
        [user]
    );

    if (!user) {
        return null;
    }

    return (
        <section className="notifs">
            <div className="notifs__field">
                <p className="notifs__field-text">Allow notifications</p>

                <div
                    className={`slide-button ${isNotifsChecked ? "checked" : ""}`}
                    onClick={() => handleUpdateNotification(!isNotifsChecked)}
                >
                    <div className="slider"></div>
                </div>
            </div>
        </section>
    );
}

export default NotificationSettings;
