import styled from "styled-components";

export const ContainerOnRemove = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  > button {
    border: none;

    padding: 15px;

    background: transparent;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
