import './TermsAndConditions.scss';
import { useState } from 'react';

import BusinessApp from '../BusinessApp/BusinessApp';
import MobileApp from '../MobileApp/MobileApp';

function TermsAndConditions() {
    const [currentTab, setCurrentTab] = useState("Mobile App")

    const tabs = [{ name: "Mobile App" }, { name: "Business Panel" }]

    return (
        <section className='terms'>
            <div className='container-tab'>
                {tabs.map((tab, index) => (
                    <button key={index} onClick={() => {
                        setCurrentTab(tab.name)
                    }}
                        className={currentTab === tab.name ? "active-tab" : ""}
                    >
                        {tab.name}
                    </button>
                ))}
            </div>


            {currentTab === 'Mobile App' ? < MobileApp /> : <BusinessApp />}
        </section>
    )
}

export default TermsAndConditions
