import './DeleteModal.scss';

import exit from '../../assets/images/icons/exit.svg';
import Button from '../Buttons/Button';

function DeleteModal({ handleDelete, setDeleteModal, modToDelete, title, message, tableName, button, isLoading }) {

    return (
        <section className='delete-modal'>
            <article className='delete-modal__modal'>
                <div className='delete-modal__modal-top'>
                    {title}
                    <img onClick={() => setDeleteModal(false)} src={exit} alt='white x'></img>
                </div>
                <div className='delete-modal__modal-content'>
                    {message || (
                        <>
                            <p className='delete-modal__modal-content-text'>
                                ARE YOU SURE YOU WANT TO DELETE
                            </p>
                            <p className='delete-modal__modal-content-text'>
                                {modToDelete.firstName} {modToDelete.lastName}
                            </p>
                            <p className='delete-modal__modal-content-text'>
                                FROM THE {tableName} TABLE?
                            </p>
                        </>
                    )}
                </div>
                <div className='d-flex align-item-center gap-3 justify-content-center pb-4 pt-2'>
                    <Button 
                        className='btn-outline-light'
                        onClick={() => setDeleteModal(false)}
                        label='Cancel'
                    />
                    <Button 
                        primaryBtn
                        onClick={() => handleDelete(modToDelete.id)}
                        label={isLoading ? 'Deleting...' : (button || 'Delete')}
                        disabled={isLoading}
                    />
                </div>
            </article>
        </section>
    )
}

export default DeleteModal;