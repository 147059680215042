import { useEffect, useState } from "react";
import { MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { parseISO, format } from "date-fns";

import SideBar from "../../../components/SideBar/SideBar";
import TableColumn from "../../../components/TableColumn/TableColumn";
import TopNav from "../../../components/TopNav/TopNav";
import api from "../../../services/api";

const UserQueries = () => {
  const navigate = useNavigate();

  const [queries, setQueries] = useState();

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("ListCurrentPageUserQueries")) || 1
  );
  const records = 10;
  const sortableColumns = ["User", "Time", "Status"];
  const columns = ["User", "Time", "Text", "Status"];

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("null");

  const formatDate = (isoDate) => {
    const date = parseISO(isoDate);
    return format(date, "MMM dd yyyy, HH:mm");
  };

  useEffect(() => {
    const getSupports = async (page) => {
      try {
        const response = await api.get(`/supports`, {
          params: {
            records: 10,
            page: page,
            type: "user",
            sort_column: sortBy,
            sort: sortDirection,
          },
        });

        const itensTableColumns = response.data.data.map((queries) => ({
          _id: queries._id,
          user_id: queries.user_id,
          Text: queries.description,
          Time: formatDate(queries.created_at),
          User: queries.user_name,

          Status:
            (queries.status === "opened" ? "open" : queries.status)
              .charAt(0)
              .toUpperCase() +
            (queries.status === "opened" ? "open" : queries.status).slice(1),
        }));

        setQueries(itensTableColumns);
        setTotalPages(response.data.total);
      } catch (error) {
        console.log(`users get request didn't work. error: ${error}`);
      }
    };

    getSupports(currentPage);
  }, [navigate, currentPage, sortBy, sortDirection]);

  const actions = [
    {
      label: <MdEdit size={30} color="rgba(167, 41, 245, 1)" />,

      onClick: (rowData) => {
        console.log(rowData);
        navigate(`/queries/edit/${rowData._id}`);
      },
    },
    {
      label: <MdOutlineRemoveRedEye size={30} color='rgba(167, 41, 245, 1)' />,

      onClick: (rowData) => {
        console.log(rowData);
        navigate(`/users/${rowData.user_id}`);
      },
    },
  ];

  const getStatusColor = (status) => {
    const capitalizedStatus =
      status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

    switch (capitalizedStatus) {
      case "Opened":
        return "rgba(46, 204, 113, 0.1)";
      case "Canceled":
        return "status-declined";
      case "Solved":
        return "status-pending";
      default:
        return "inherit";
    }
  };

  const fetchAllData = async () => {
    try {
      const response = await api.get(`/supports`, {
        params: {
          records: 9999999999,
          type: "user",
        },
      });

      return response.data.data.map((queries) => ({
        _id: queries._id,
        Text: queries.description,
        Time: formatDate(queries.created_at),
        User: queries.user_name,

        Status:
          (queries.status === "opened" ? "open" : queries.status)
            .charAt(0)
            .toUpperCase() +
          (queries.status === "opened" ? "open" : queries.status).slice(1),
      }));
    } catch (error) {
      console.log(`Error fetching all data: ${error}`);
      return [];
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("ListCurrentPageUserQueries", page);
  };

  useEffect(() => {
    const storedPage = localStorage.getItem("ListCurrentPageUserQueries");

    setCurrentPage(Number(storedPage) || 1);
  }, []);

  const sortColumnsMapping = {
    User: "user_name",
    Time: "created_at",
    Status: "status",
  };

  const handleSort = (columnName) => {
    const backendColumnName = sortColumnsMapping[columnName];
    if (sortBy === backendColumnName) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(backendColumnName);
      setSortDirection("asc");
    }
  };

  return (
    <section className="queries">
      <TableColumn
        columns={columns}
        data={queries}
        sortableColumns={sortableColumns}
        hasExport={true}
        hasSearch={false}
        actions={actions}
        getStatusColor={getStatusColor}
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        records={records}
        fetchAllData={fetchAllData}
        onSort={handleSort}
      />
    </section>
  );
};

export default UserQueries;
